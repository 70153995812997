import axios from "axios";
export default {
	data() {
		return {
			selected: null,
			brands: [],
			visibleBrands: [],
			length: 0,
			searchText: null,
			brandNames: [],
			values: null,
			value: null,
			loading: true,
			search: '',
			name: null,
			finalidade: null,

			headers: [
				{
					text: 'Nome da instituição',
					align: 'start',
					sortable: false,
					value: 'CustomerFriendlyName',
				},

			],

		};
	},

	methods: {
		async listBrands() {
			const response = await axios.get("/api/smart-keys/brands/v1/brands?type=DATA");
			this.brands = response.data;
			this.loading = false;
		},

		nextView() {
			this.$store.commit("setBrand", this.brand);
			this.$router.push({ name: "resumeDataView" });
		},

		async cancel(){
			await axios.post(`/api/smart-keys/interceptors/webhooks`, {
				type: "PAYMENT",
				event: "journey:cancel",
				data: {
						journeyId: this.$store.state.paymentId
				}
			});
			const callbackPage = this.$store.state.callbackClientPage;
			window.open(callbackPage, '_self');
		},

		rowClick: function (item, row) {
			row.select(true);
			this.selected = true;
			this.brand = item
		},

		sendToHomePage() {
			return this.$router.push({ path: `/consent-home?id=${this.$store.state.journeyid}` });
		}
	},

	beforeMount() {
		this.listBrands();
		this.name = this.$store.state.name;
		this.finalidade = this.$store.state.finalidade
	},
};