import moment from "moment";
import axios from "axios";
import paymentFooter from "../../../components/payment-journey/payment-footer/payment-footer.vue";
import paymentHeader from "../../../components/payment-journey/payment-header/payment-header.vue";

export default {
	components: { paymentFooter, paymentHeader },
	data() {
		return {
			completed: false,
			date: null,
			picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
			.toISOString().substr(0, 10),
			formatedDate: null,
			amount: 0,
			menu: null,
			dialogCompose: false,
			loading: true
		}
	},
	watch: {
		'picker'(){
			this.date = moment(this.picker);
		},
		'date'(e){
			this.verifyDates(e);
		}
	},
	methods: {
		verifyDates(date){
			var schedule = moment(date);
			var now = moment();

			if(now >= schedule){
				this.$store.commit("setIsCurrent", true);
				this.formatedDate = now.format('DD/MM/YYYY');
			}else{
				this.$store.commit("setIsCurrent", false);
				this.formatedDate = schedule.format('DD/MM/YYYY');
			}
		},
		now(){
			this.date = moment();
		},
		next(){
			this.$store.commit("setAmount", this.amount);
			this.$store.commit("setDateSchedule", this.date);
			this.$router.push({ name: "DepositConfirmationSimple" });
		},
		async cancel(){
			await axios.post(`/open-keys-itp/api/journey/${this.$store.state.paymentId}`, { status: "CANCEL" });
			window.location.href = this.$store.state.callbackClientPage;
		}
	},

	async beforeMount() {
		const paymentId = this.$route.query.id
		this.$store.commit("setPaymentId", paymentId);
		let verify = {};
		try{
			verify = await axios.get(`/open-keys-itp/api/auth/v1/verify-id?id=${this.$route.query.id}`);
			await axios.post(`/open-keys-itp/api/interceptors/webhooks`, {
				event: "journey:started",
				data: {
					journey: this.$route.query.id
				},
				journeyId: this.$route.query.id
			});

		}catch(error){
			if(error.response.status === 404){
				this.$router.push({ name: "JourneyError", query: { type: "journeyid_not_found", createdAt: new Date()}});
			}
			if(error.response.status === 422){
				this.$router.push({ name: "JourneyError", query: { type: error.response.data.message, createdAt: new Date()}});
			}
		}

		const application = await axios.get(`/open-keys-itp/api/auth/v1/applications/${verify.data.clientId}`);
		this.$store.commit("setApplicationName", application.data.name);
		this.$store.commit("setApplicationLogo", application.data.logo);
		this.$store.commit("setCallbackClientPage", application.data.redirect_client);
		this.$store.commit("setName", verify.data.name);
		this.$store.commit("setAmount", verify.data.amount);
		this.$store.commit("setPaymenttype", verify.data.paymenttype);
		if(verify.data.paymenttype === "DICT"){
			this.$store.commit("setCreditorAccount", application.data.creditorAccount);
		}
		if(verify.data.paymenttype === "MANU"){
			this.$store.commit("setCreditorAccount", verify.data.creditorAccount);
			this.$store.commit("setCreditor", verify.data.creditor);
		}
		if(application.data.primaryColor){
			this.$store.commit("setPrimaryColor", application.data.primaryColor);
		}else{
			this.$store.commit("setPrimaryColor", "#8E19FF");
		}
		this.$store.commit("setClientId", verify.data.clientId);
		this.$store.commit("setCpf", verify.data.cpf);
		this.$store.commit("setCnpj", verify.data.cnpj);
		this.$store.commit("setPaymentview", verify.data.paymentview);

		this.$store.commit("setMetadata", verify.data.metadata);
		this.$store.commit("setRedirectUri", verify.data.redirectUri);

		this.$store.commit("setPreferredbanks", verify.data.preferredbanks);
		this.$store.commit("setPreselectedbank", verify.data.preselectedbank);
		this.$store.commit("setDescription", verify.data.description);

		this.$store.commit("setDebtorAccount", verify.data.debtorAccount);

		this.amount = verify.data.amount;

		if(verify.data.paymentview === "deposit-confirmation-simple"){
			this.date = moment();
			if(verify.data.preselectedbank){
				try{
					const response = await axios.get(`/open-keys-itp/api/brands/v1/brands/${verify.data.preselectedbank}?type=PAYMENT`);
					this.$store.commit("setBrand", verify.data.preselectedbank);
					this.$store.commit("setCustomerFriendlyName", response.data.CustomerFriendlyName);
					this.$store.commit("setCustomerFriendlyLogoUri", response.data.CustomerFriendlyLogoUri);
					this.$store.commit("setAmount", this.amount);
					this.$store.commit("setDateSchedule", this.date);
					this.$router.push({ name: "DepositConfirmSimple" });
				}catch(error){
					this.next();
				}
			}else{
				this.next();
			}

		}else{
			if(verify.data.date && verify.data.amount){
				this.date = moment(verify.data.date);
				this.next();
			}
		}
		this.loading = false;
	}
}