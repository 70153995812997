import Vue from "vue";
import VueRouter from "vue-router";

import store from '../plugins/store';
import axios from "axios";

import InitialMessageView from "../views/initial-message-view/initial-message-view.vue";
import SelectBrandView from "../views/select-brand-view/select-brand-view.vue";
import ResumeDataView from "../views/resume-data-view/resume-data-view.vue";
import StatusView from "../views/status-view/status-view.vue";
import CallbackView from "../views/callback-view/callback-view.vue";
import LoginView from "../views/login-view/login-view.vue";
import ConsentHome from "../views/consent/home/consent-home.vue";
import ConsentAbout from "../views/consent/about/consent-about.vue";
import ConsentList from "../views/consent/list/consent-list.vue";
import ConsentDetail from "../views/consent/detail/consent-detail.vue";
import ConsentUpdate from "../views/consent/update/consent-update.vue";
import DepositType from "../views/payment-initiation-view/deposit-type-view/deposit-type-view.vue";
import SelectInstitution from "../views/payment-initiation-view/select-institution-view/select-institution-view.vue";
import DepositScheduling from "../views/payment-initiation-view/deposit-scheduling-view/deposit-scheduling-view.vue";
import DepositConfirmation from "../views/payment-initiation-view/deposit-confirmation-view/deposit-confirmation-view.vue";
import Redirection from "../views/payment-initiation-view/redirection-view/redirection-view.vue";
import CallbackViewMobile from "../views/callback-view-mobile/callback-view-mobile.vue";
import PaymentView from "../views/payment-manager/payment-view/payment-view.vue";
import PaymentDetailView from "../views/payment-manager/payment-details-view/payment-details-view.vue";
import DepositConfirmationSimple from "../views/payment-initiation-view/deposit-confirmation-simple-view/deposit-confirmation-simple-view.vue";
import DepositConfirmSimple from "../views/payment-initiation-view/deposit-confirm-simple-view/deposit-confirm-simple-view.vue";
import TransmitterRedirect from "../views/payment-initiation-view/transmitter-redirect/transmitter-redirect.vue";
import TransmitterRedirectError from "../views/payment-initiation-view/transmitter-redirect-error/transmitter-redirect-error-view.vue";
import PaymentSmart from "../views/payment-initiation-view/payment-smart-view/payment-smart-view.vue";
import JourneyError from "../views/journey-error/journey-error.vue";
import EditPreSelectedBank from "../views/payment-initiation-view/edit-pre-selected-bank/edit-pre-selected-bank.view.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/login",
		name: "loginView",
		component: LoginView
	},
	{
		path: "/",
		name: "initialMessageView",
		component: InitialMessageView
	},
	{
		path: "/brands",
		name: "selectBrandView",
		component: SelectBrandView
	},
	{
		path: "/resume",
		name: "resumeDataView",
		component: ResumeDataView
	},
	{
		path: "/status",
		name: "statusView",
		component: StatusView
	},
	{
		path: "/callback",
		name: "callbackView",
		component: CallbackView
	},
	{
		path: "/consent-home",
		name: "consentHome",
		component: ConsentHome
	},
	{
		path: "/consent-about",
		name: "consentAbout",
		component: ConsentAbout
	},
	{
		path: "/consent-list",
		name: "consentList",
		component: ConsentList
	},
	{
		path: "/consent-detail/:id",
		name: "consentDetail",
		component: ConsentDetail,
	},
	{
		path: "/consent-update/:id",
		name: "consentUpdate",
		component: ConsentUpdate,
	},
	{
		path: "/consent-renew/:id",
		name: "consentRenew",
		component: ConsentUpdate,
	},
	{
		path: "/deposit-type",
		name: "depositType",
		component: DepositType,
	},
	{
		path: "/select-institution",
		name: "selectInstitution",
		component: SelectInstitution,
	},
	{
		path: "/deposit-scheduling/:id",
		name: "depositScheduling",
		component: DepositScheduling,
	},
	{
		path: "/deposit-confirmation/:id",
		name: "depositConfirmation",
		component: DepositConfirmation,
	},
	{
		path: "/redirection",
		name: "redirection",
		component: Redirection,
	},
	{
		path: "/payments",
		name: "PaymentView",
		component: PaymentView,
	},
	{
		path: "/payment-detail",
		name: "PaymentDetailView",
		component: PaymentDetailView,
	},
	{
		path: "/deposit-confirmation-simple",
		name: "DepositConfirmationSimple",
		component: DepositConfirmationSimple,
	},
	{
		path: "/deposit-confirm-simple",
		name: "DepositConfirmSimple",
		component: DepositConfirmSimple,
	},
	{
		path: "/transmitter-redirect",
		name: "TransmitterRedirect",
		component: TransmitterRedirect,
	},
	{
		path: "/transmitter-redirect-error",
		name: "TransmitterRedirectError",
		component: TransmitterRedirectError,
	},
	{
		path: "/callback-smart",
		name: "callbackViewMobile",
		component: CallbackViewMobile
	},
	{
		path: "/payment-smart",
		name: "PaymentSmart",
		component: PaymentSmart
	},
	{
		path: "/journey-error",
		name: "JourneyError",
		component: JourneyError
	},
	{
		path: "/selected-bank",
		name: "EditPreSelectedBank",
		component: EditPreSelectedBank
	}
]

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

// router.afterEach(async (req) => {
// 	if(req.name != 'callbackView' && req.name != 'callbackViewMobile'){
// 		let journeyId;
// 		if(req.query.id){
// 			journeyId = req.query.id;

// 			try{
// 				await axios.get(`/open-keys-itp/api/auth/v1/verify-id?id=${journeyId}`);
// 			}catch(error){
// 				router.push({ name: "JourneyError", query: { type: error.response.data.type, createdAt: error.response.data.createdAt}});
// 			}

// 		}
// 	}
// });

export default router;