import axios from "axios";
import paymentFooter from "../../../components/payment-journey/payment-footer/payment-footer.vue";
import paymentHeader from "../../../components/payment-journey/payment-header/payment-header.vue";

/** @type {import("vue").Component} @vue/component */
export default ({
	components: {paymentFooter, paymentHeader },
	data: () => ({
        loading: true,
        brands: [],
        simpleBrands: [],
        search: '',
		primaryColor: null
	}),
	async beforeMount() {
		this.primaryColor = this.$store.state.primaryColor;
        await this.listSimpleBrands();
		await this.listBrands();
		this.loading = false;
	},
    watch: {
		search: {
			deep: true,
			handler: _.debounce(function() {
				if(this.search.length != 0){
					let search = this.search;
					this.simpleBrands = this.brands.filter(function(item){
						if (item.CustomerFriendlyName.toLowerCase().includes(search.toLowerCase()) && this.count < 6){
							this.count ++
							return true;
						}
						return false;
					}, {count: 0, search})
				}else{
					this.listSimpleBrands();
				}
			}, 500)
		},
	},
	methods: {
        async listSimpleBrands() {
			if(this.$store.state.preferredbanks){
				const params = new URLSearchParams();
				this.$store.state.preferredbanks.forEach((value, index) => { 
				params.append(`preferredbanks`, value);
				});
				const response = await axios.get("/api/smart-keys/payment-initiation/consents/brands", {params});
				for(const responsedata of response.data){
					this.simpleBrands.push({ AuthorisationServerId: responsedata.brandId, CustomerFriendlyName: responsedata.organizationName, CustomerFriendlyLogoUri: responsedata.organizationLogo });
				}
			}else{
				const response = await axios.get("/open-keys-itp/api/brands/v1/brands?type=PAYMENT&isFavorite=true&limit=8");
				this.simpleBrands = response.data
			}
		},

		async listBrands() {
			const response = await axios.get(`/open-keys-itp/api/brands/v1/brands/?type=PAYMENT`);
			this.brands = response.data
		},

        lastPage() {
			this.$router.go(-1)
		},

        selectInstitution(brand) {
			this.$store.commit("setBrand", brand.AuthorisationServerId);
			this.$store.commit("setCustomerFriendlyName", brand.CustomerFriendlyName);
			this.$store.commit("setCustomerFriendlyLogoUri", brand.CustomerFriendlyLogoUri);
			this.$router.push({ name: 'DepositConfirmSimple' })
		}
	}
});
