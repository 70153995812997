import axios from "axios";

export default {

	data() {
		return {
		
		}

	},

	methods: {
		sendToHomePage(){
			return this.$router.push({ path: `/consent-home?id=${this.$store.state.journeyid}` });
		}
	}
};