import axios from "axios";

export default {
	data() {
		return {
			id: null,
			name: null,
			integration: null,
		}

	},

	methods: {
		opin() {
			window.open('https://openfinancebrasil.org.br/', '_blank');
		},
		async runJourney() {
			return this.$router.push({ path: `/?id=${this.$store.state.journeyid}` });
		},
  },

	async beforeMount() {
		let paymentId = this.$route.query.id;
		this.$store.commit("setPaymentId", paymentId);

		const verify = await axios.get(`/api/smart-keys/auth/v1/verify-id?id=${this.$route.query.id}`);
		const application = await axios.get(`/api/smart-keys/auth/v1/applications/${verify.data.id}`);
		
		this.integration = application.data.integration
		this.$store.commit("setJourneyid", this.$store.state.journeyid);

		this.id = verify.data.id;
		this.$store.commit("setName", verify.data.name);
		this.name = verify.data.name;
		if(verify.data.page === "consent-list" && !this.$route.query.return){
			this.$router.push({ name: "consentList" });
		}
		if(verify.data.page === "payment-list" && !this.$route.query.return){
			this.$router.push({ name: "PaymentView" });
		}
	}

};