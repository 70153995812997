import axios from "axios";
import moment from "moment";
import Terms from "../../../components/terms-and-conditions/terms.vue";
import paymentFooter from "../../../components/payment-journey/payment-footer/payment-footer.vue";
import paymentHeader from "../../../components/payment-journey/payment-header/payment-header.vue";

/** @type {import("vue").Component} @vue/component */
export default ({
	components: { Terms, paymentFooter, paymentHeader },
	data: () => ({
		idBrand: null,
		name: '',
		amount: 0,
		cpf: '',
		cnpj: '',
		customerFriendlyName: '',
		customerFriendlyLogoUri: '',
		loading: false,
		date: null,
		date_now: '',
		bodyPayment: {},
		isCurrent: null,
		modal: false,
		preselectedbank: false,
		primaryColor: null
	}),
	async beforeMount() {
		this.date = this.$store.state.dateSchedule;
		this.date_schedule = moment(this.date).toISOString().split("T")[0];
		this.date_now = moment(this.date).format('YYYY-MM-DD');

		this.idBrand = this.$store.state.brand;
		this.cpf = this.$store.state.cpf;
		this.cnpj = this.$store.state.cnpj;
		this.name = this.$store.state.name;
		this.amount = this.$store.state.amount;
		this.customerFriendlyName = this.$store.state.customerFriendlyName;
		this.customerFriendlyLogoUri = this.$store.state.customerFriendlyLogoUri;
		this.isCurrent = this.$store.state.isCurrent;
		this.loading = false;
		this.primaryColor = this.$store.state.primaryColor;

		if(this.$store.state.paymenttype === "DICT"){

			const application = await axios.get(`/api/smart-keys/auth/v1/applications/${this.$store.state.clientId}`);
			let personType = application.data.creditorAccount.type;

			if(personType === "LEGAL_PERSON"){
				personType = "PESSOA_JURIDICA";
			}
			if(personType === "NATURAL_PERSON"){
				personType = "PESSOA_NATURAL";
			}

			if(application.data.creditorAccount){
				this.bodyPayment = {
					data: {
						loggedUser: {
							document: {
								identification: this.cpf.replace(/\D+/g, ''),
								rel: "CPF",
							},
						},
						creditor: {
							cpfCnpj: application.data.creditorAccount.taxIdNumber,
							name: application.data.creditorAccount.name,
							personType,
						},
						payment: {
							amount: this.amount,
							currency: "BRL",
							date: this.date_now,
							details: {
								creditorAccount: {
									accountType: application.data.creditorAccount.accountType,
									ispb: application.data.creditorAccount.participant,
									issuer: application.data.creditorAccount.branch.toString(),
									number: application.data.creditorAccount.accountNumber,
								},
								localInstrument: "DICT",
								proxy: application.data.creditorAccount.pixKey,
							},
							type: "PIX",
						},
					},
					brandId: this.idBrand,
				}

				this.bodySchedule = {
					data: {
						loggedUser: {
							document: {
								identification: this.cpf.replace(/\D+/g, ''),
								rel: "CPF",
							},
						},
						creditor: {
							cpfCnpj: application.data.creditorAccount.taxIdNumber,
							name: application.data.creditorAccount.name,
							personType,
						},
						payment: {
							amount: this.amount,
							currency: "BRL",
							schedule: {
								single: {
									date: this.date_schedule,
								},
							},
							details: {
								creditorAccount: {
									accountType: application.data.creditorAccount.accountType,
									ispb: application.data.creditorAccount.participant,
									issuer: application.data.creditorAccount.branch.toString(),
									number: application.data.creditorAccount.accountNumber,
								},
								localInstrument: "DICT",
								proxy: application.data.creditorAccount.pixKey,
							},
							type: "PIX",
						},
					},
					brandId: this.idBrand,
				}

			}
		}

		if(this.$store.state.paymenttype === "MANU"){

			this.bodyPayment = {
				data: {
					loggedUser: {
						document: {
							identification: this.cpf.replace(/\D+/g, ''),
							rel: "CPF",
						},
					},
					creditor: this.$store.state.creditor,
					payment: {
						amount: this.amount,
						currency: "BRL",
						date: this.date_now,
						details: {
							creditorAccount: this.$store.state.creditorAccount,
							localInstrument: "MANU",
						},
						type: "PIX",
					},
				},
				brandId: this.idBrand,
			}

			this.bodySchedule = {
				data: {
					loggedUser: {
						document: {
							identification: this.cpf.replace(/\D+/g, ''),
							rel: "CPF",
						},
					},
					creditor: this.$store.state.creditor,
					payment: {
						amount: this.amount,
						currency: "BRL",
						schedule: {
							single: {
								date: this.date_schedule,
							},
						},
						details: {
							creditorAccount: this.$store.state.creditorAccount,
							localInstrument: "MANU",
						},
						type: "PIX",
					},
				},
				brandId: this.idBrand,
			}
		}

		if(this.$store.state.metadata){
			this.bodyPayment.metadata = this.$store.state.metadata;
		}

		if(this.$store.state.debtorAccount){
			this.bodyPayment.data.debtorAccount = this.$store.state.debtorAccount;	
		}

		if(this.$store.state.redirectUri){
			this.bodyPayment.redirectUri = this.$store.state.redirectUri;
		}

		if(this.$store.state.description){
			this.bodyPayment.description = this.$store.state.description;
		}

		if(this.$store.state.cnpj){
			this.bodyPayment.data.businessEntity = {
				document:{
					identification: this.$store.state.cnpj.replace(/\D+/g, ''),
					rel: "CNPJ",
				}
			}
		}

		if (this.$store.state.preselectedbank) {
			this.preselectedbank = true
		}

	},
	methods: {
		async confirmDeposit() {

			this.loading = true;

			this.dialog_warning = false;

			try {
				
				this.$router.push({ name: "TransmitterRedirect" } );

				const response = await axios.post(
					"/open-keys-itp/api/payment-initiation/v1/consents",
					this.isCurrent ? this.bodyPayment : this.bodySchedule, { headers: { "x-journey-id": this.$store.state.paymentId } }
				);
				this.redirect(response.data.authorizationUrl);

			} catch (error) {
				if(error?.response?.data?.code === 422 && error?.response?.data?.message === "JOURNEY_EXISTS"){
					this.$router.push({ name: "JourneyError", query: { type: "journeyid_consumed", createdAt: new Date()}});
				}else{
					this.$router.push({ name: "TransmitterRedirectError" });
				}
			}
		},
		maskCpfCnpj(item, type) {
			if (!item) return;
			if (type === "cpf") {
				return item.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "***.$2.$3-**");
			} else {
				return item.replace(
					/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
					"**.$2.$3/$4-**"
				);
			}
		},
		redirect(authorizationUrl) {
			setTimeout(() => {
				window.location.href = authorizationUrl;
				// window.open(authorizationUrl);
			}, 0);
		},
		lastPage() {
			this.$router.go(-1)
		},
		async cancel(){
			await axios.put(`/open-keys-itp/api/journey/${this.$store.state.paymentId}`, { status: "CANCEL" });
			let tags = undefined;
			if(this.$store.state?.metadata?.tags){
				tags = '&' + new URLSearchParams(this.$store.state?.metadata?.tags).toString()
			}
			window.location.href = this.$store.state.callbackClientPage + 
								`?error=journey_cancel&error_description=Jornada%20rejeitado%20pelo%20usu%C3%A1rio&journey-id=${this.$store.state.paymentId}` + 
								(this.$store.state?.metadata?.externalId ? `&externalId=${this.$store.state.metadata.externalId}` : "") + 
								(tags ? `${tags}` : "");
		},
		selectBank() {
			this.$router.push({ name: "EditPreSelectedBank" });
		},
	}
});
