import _ from "lodash";
import moment from "moment";
import Loading from "../../../components/loading/loading.vue"
import ConsentDialog from "../../../components/consent/dialog/consent-dialog.vue";
import axios from "axios";
import { VBtn } from "vuetify/lib";

/** @type {import("vue").Component} @vue/component */
export default ({
	components: { VBtn, ConsentDialog, Loading },
	async created() {
		await axios.get(`/api/smart-keys/data-reception/v1/consents/${this.$route.params.id}`)
		.then( (consent) => {
			this.consent = consent.data;
		});
	},
	data: () => ({
		consent: {}
	}),
	methods: {
		formatDate(date) {
			if (!date) return null;
			const [ year, month, day ] = date.split("-");
			return this.$i18n.locale === "pt_BR" ? `${day}/${month}/${year}` : `${month}/${day}/${year}`;
		},
		async revoke(item) {
			if (await this.$confirm("Confirma o encerramento do compartilhamento?", { buttonFalseText: "Não", buttonTrueText: "Sim" })) {
				this.loading = true;
				try {
					await axios.delete(`/api/smart-keys/data-reception/v1/consents/${item.id}`);
					this.consent.status = "REJECTED";
				} catch (error) {
					this.$toast.error(error);
				}
				this.loading = false;
			}
		},
		async aprove(item) {
			if (await this.$confirm("Confirma aprovação do compartilhamento?", { buttonFalseText: "Não", buttonTrueText: "Sim" })) {
				this.loading = true;

				const response = await axios.post(`/api/smart-keys/data-reception/v1/consents/${item.id}/approve`);

				if (!response.data.authorizationUrl) {
					return this.$router.push({ name: "consentList" });
				}
	
				setTimeout(() => {
					window.location.replace(response.data.authorizationUrl);
				}, 5000);

				this.loading = false;
			}
		},
		convertDate(date) {
			const data = moment.utc(date);
			const format = data.format("DD/MM/YYYY");
			if (format !== "Invalid date") {
				return format;
			}
			return null;
		},
		mountDateAndHour(date) {
			const data = moment.utc(date);
			const time = data.format("DD/MM/YYYY");
			const hour = data.format("HH:MM");
			if (hour !== "Invalid date" && time !== "Invalid date") {
				return `${time} às ${hour}`;
			}
			return null;
		}
	}
});