import axios from "axios";
import moment from "moment";

export default {
	data() {
		return {
			brand: null,
			cpf: null,
			cnpj: null,
			name: null,
			dialog: false,
			expirationDateTimeSelected: null,
			deadline: [
				{ text: "6 meses", value: 6 },
				{ text: "9 meses", value: 9 },
				{ text: "12 meses", value: 12 }
			],
			opened: 0,
			checkbox: true,
			loading: false,
			authorizationUrl: null,
			cpf: null,
			cnpj: null,
			permissions: [
				"ACCOUNTS_READ",
				"ACCOUNTS_OVERDRAFT_LIMITS_READ",
				"ACCOUNTS_BALANCES_READ",
				"ACCOUNTS_TRANSACTIONS_READ",
				"CREDIT_CARDS_ACCOUNTS_BILLS_READ",
				"CREDIT_CARDS_ACCOUNTS_BILLS_TRANSACTIONS_READ",
				"CREDIT_CARDS_ACCOUNTS_LIMITS_READ",
				"CREDIT_CARDS_ACCOUNTS_READ",
				"CREDIT_CARDS_ACCOUNTS_TRANSACTIONS_READ",
				"CUSTOMERS_PERSONAL_ADITTIONALINFO_READ",
				"CUSTOMERS_PERSONAL_IDENTIFICATIONS_READ",
				"LOANS_READ",
				"LOANS_WARRANTIES_READ",
				"LOANS_SCHEDULED_INSTALMENTS_READ",
				"LOANS_PAYMENTS_READ",
				"FINANCINGS_READ",
				"FINANCINGS_WARRANTIES_READ",
				"FINANCINGS_SCHEDULED_INSTALMENTS_READ",
				"FINANCINGS_PAYMENTS_READ",
				"UNARRANGED_ACCOUNTS_OVERDRAFT_READ",
				"UNARRANGED_ACCOUNTS_OVERDRAFT_WARRANTIES_READ",
				"UNARRANGED_ACCOUNTS_OVERDRAFT_SCHEDULED_INSTALMENTS_READ",
				"UNARRANGED_ACCOUNTS_OVERDRAFT_PAYMENTS_READ",
				"INVOICE_FINANCINGS_READ",
				"INVOICE_FINANCINGS_WARRANTIES_READ",
				"INVOICE_FINANCINGS_SCHEDULED_INSTALMENTS_READ",
				"INVOICE_FINANCINGS_PAYMENTS_READ",
				"RESOURCES_READ",
			],

			permissionsSend: null,
			dialogBrand: false,
			accounts: true,
			customersPersonal: true,
			creditCards: true,
			creditOperation: true,
			scopes: {},

			requiredAccounts: false,
			requiredCustomers: false,
			requiredCreditCards: false,
			requiredCreditOperation: false,

			brandParticipants: [],
			loadingOverlay: false,
			erroMessage: false,

			panel: 0,

			dialogPermissions: false,
			scopeName: null,
			listOfPermissions: [],


		};
	},
	created() {
		if (!this.$store.state.brand) {
			return this.$router.push({ name: "consentHome" });
		}

		this.expirationDateTimeSelected = this.deadline[2];
	},
	computed: {
		expirationDateTime() {
			return moment().add(this.expirationDateTimeSelected.value, "months").format("DD/MM/YYYY");
		},
		organizationName() {
			return this.$store.state.brand?.CustomerFriendlyName;
		},

		formatCpf() {
			const badchars = /[^\d]/g
			const mask = /(\d{3})(\d{3})(\d{3})(\d{2})/
			const cpf = new String(this.cpf).replace(badchars, "");
			return cpf.replace(mask, "$1.$2.$3-$4");
		},

		formatCnpj() {
			const badchars = /[^\d]/g;
			const mask = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;
			const cnpj = new String(this.cnpj).replace(badchars, "");

			return cnpj.replace(mask, "$1.$2.$3/$4-$5");
		}
	},
	methods: {

		sendToHomePage() {
			return this.$router.push({ path: `/consent-home?id=${this.$store.state.journeyid}` });
		},

		async createConsent() {
			this.loading = true;

			let cpf = "76109277673"
			if (this.$store.state.cpf) {
				cpf = this.$store.state.cpf;
			}

			const body = {
				data: {
					loggedUser: {
						document: {
							identification: cpf,
							rel: "CPF",
						},
					},
					permissions: Object.values(this.permissions),
					expirationDateTime: moment().add(this.expirationDateTimeSelected.value, "months").format("YYYY-MM-DDTHH:mm:ss\\Z")
				},
				brandId: this.brand.AuthorisationServerId
			};

			if (this.$store.state.cnpj) {
				body.data.businessEntity = { "document": { "identification": `${this.$store.state.cnpj}`, "rel": "CNPJ" } };
				body.data.permissions.splice(body.data.permissions.indexOf("CUSTOMERS_PERSONAL_ADITTIONALINFO_READ"), 1);
				body.data.permissions.splice(body.data.permissions.indexOf("CUSTOMERS_PERSONAL_IDENTIFICATIONS_READ"), 1);
				body.data.permissions.push("CUSTOMERS_BUSINESS_ADITTIONALINFO_READ");
				body.data.permissions.push("CUSTOMERS_BUSINESS_IDENTIFICATIONS_READ");
			}

			try {
				const response = await axios.post("/api/smart-keys/data-reception/v1/consents", body, { headers: { "x-journey-id": this.$store.state.paymentId } });
				this.$store.commit("setAuthorizationUrl", response.data.authorizationUrl);
				this.$router.push({ name: "statusView" });
			} catch (error) {
				console.log(error);
				this.erroMessage = true;
				this.loading = false;
			}

		},
		approveTermsAndConditions() {
			this.dialog = false;
		},

		setRequiredData() {
			if (this.scopes.accounts == true) {
				this.requiredAccounts = true;
			}
			if (this.scopes.customersPersonal == true || this.scopes.customerBusiness == true) {
				this.requiredCustomers = true;
			}
			if (this.scopes.creditCards == true) {
				this.requiredCreditCards = true;
			}
			if (this.scopes.financings == true || this.scopes.loans == true) {
				this.requiredCreditOperation = true;
			}
		},

		permissionsSet(type) {
			if (type == 'accounts') {
				var accountsPermissions = ['ACCOUNTS_READ', 'ACCOUNTS_BALANCES_READ', 'ACCOUNTS_OVERDRAFT_LIMITS_READ', 'ACCOUNTS_TRANSACTIONS_READ'];
				if (this.accounts == true) {
					this.permissions.push(...accountsPermissions);
				} else {
					this.permissions = this.permissions.filter(item => !accountsPermissions.includes(item))
				}
			} else if (type == 'customersPersonal') {
				var customersPersonalPermissions = ['CUSTOMERS_PERSONAL_IDENTIFICATIONS_READ', 'CUSTOMERS_PERSONAL_ADITTIONALINFO_READ'];
				if (this.customersPersonal == true) {
					this.permissions.push(...customersPersonalPermissions);
				} else {
					this.permissions = this.permissions.filter(item => !customersPersonalPermissions.includes(item))
				}
			} else if (type == 'creditCards') {
				var creditCardsPermissions = ['CREDIT_CARDS_ACCOUNTS_READ', 'CREDIT_CARDS_ACCOUNTS_LIMITS_READ', 'CREDIT_CARDS_ACCOUNTS_TRANSACTIONS_READ', 'CREDIT_CARDS_ACCOUNTS_BILLS_READ', 'CREDIT_CARDS_ACCOUNTS_BILLS_TRANSACTIONS_READ'];
				if (this.creditCards == true) {
					this.permissions.push(...creditCardsPermissions);
				} else {
					this.permissions = this.permissions.filter(item => !creditCardsPermissions.includes(item))
				}
			} else {
				var creditOperationPermissions = [
					'LOANS_READ',
					'LOANS_WARRANTIES_READ',
					'LOANS_SCHEDULED_INSTALMENTS_READ',
					'LOANS_PAYMENTS_READ',
					'FINANCINGS_READ',
					'FINANCINGS_WARRANTIES_READ',
					'FINANCINGS_SCHEDULED_INSTALMENTS_READ',
					'FINANCINGS_PAYMENTS_READ',
					'UNARRANGED_ACCOUNTS_OVERDRAFT_READ',
					'UNARRANGED_ACCOUNTS_OVERDRAFT_WARRANTIES_READ',
					'UNARRANGED_ACCOUNTS_OVERDRAFT_SCHEDULED_INSTALMENTS_READ',
					'UNARRANGED_ACCOUNTS_OVERDRAFT_PAYMENTS_READ',
					'INVOICE_FINANCINGS_READ',
					'INVOICE_FINANCINGS_WARRANTIES_READ',
					'INVOICE_FINANCINGS_SCHEDULED_INSTALMENTS_READ',
					'INVOICE_FINANCINGS_PAYMENTS_READ'
				];

				if (this.creditOperation == true) {
					this.permissions.push(...creditOperationPermissions);
				} else {
					this.permissions = this.permissions.filter(item => !creditOperationPermissions.includes(item))
				}
			}
		},
		async openBrandInfo() {
			this.loadingOverlay = true;

			try {
				const response = await axios.get(`/api/smart-keys/participants/v1/participants/${this.brand.OrganisationId}`)
				this.brandParticipants = response.data.AuthorisationServers;
			} catch (error) {
				console.log(error);
			}
			this.dialogBrand = true;
			this.loadingOverlay = false;
		},

		showDialogPermissions(scopeName) {
			this.scopeName = scopeName

			if (scopeName == 'Dados Cadastrais') {
				this.listOfPermissions = [
					'Nome completo e nome social',
					'Endereço completo',
					'CPF',
					'Passaporte',
					'Telefone',
					'E-mail',
					'Documento de identificação',
					'Filiação',
					'Data de nascimento',
					'Estado civil',
					'Sexo',
					'Nacionalidade',
					'Residência brasileira',
					'Documento estrangeiro',
					'Renda',
					'Profissão',
					'Patrimônio',
					'Informações de cônjuge',
					'Produtos contratados',
					'Representantes'
				]
			} else if (scopeName == 'Conta') {
				this.listOfPermissions = [
					'Informações da conta',
					'Saldo disponível ',
					'Saldo bloqueado',
					'Outros saldos',
					'Limite utilizado',
					'Limite contratado de cheque-especial',
					'Detalhes da transação'
				]
			} else if (scopeName == 'Cartões de Crédito') {
				this.listOfPermissions = [
					'Informações do cartão',
					'Informações sobre o limite do cartão',
					'Limite total',
					'Limite utilizado',
					'Limite disponível',
					'Limite por tipo de crédito',
					'Identificação de transação',
					'Valor da transação',
					'Informações do cartão',
					'Bandeira do cartão'
				]
			}else  {
				this.listOfPermissions = [
					'Dados do contrato',
					'Data da contratação',
					'Data do recebimento do crédito ',
					'Valor do crédito',
					'Data de vencimento',
					'Datas de pagamento das parcelas ',
					'Datas de vencimento de cada parcela ',
					'Data de vencimento da primeira	parcela',
					'Saldo devedor',
					'Prazo total',
					'Prazo remanescente ',
					'Quantidade de prestações',
					'Quantidade de prestações',
					'Prestações',
					'Taxas de juros',
					'Custo Efetivo Total ',
					'Sistema de amortização',
					'Tarifas',
					'Sigla identificadora da tarifa',
					'Valor da tarifa',
					'Moeda',
					'Data da cobrança de tarifa',
					'Encargos',
					'Garantias',
					'Número do documento da instituição '
				]
			}

			this.dialogPermissions = true;
		},
		async cancel(){
			await axios.post(`/api/smart-keys/interceptors/webhooks`, {
				type: "PAYMENT",
				event: "journey:cancel",
				data: {
						journeyId: this.$store.state.paymentId
				}
			});
			const callbackPage = this.$store.state.callbackClientPage;
			window.open(callbackPage, '_self');
		}
	},

	beforeMount() {
		this.permissionsSend = this.permissions;
		this.brand = this.$store.state.brand
		this.cpf = this.$store.state.cpf;
		this.cnpj = this.$store.state.cnpj;
		this.name = this.$store.state.name;
		this.scopes = this.$store.state.scopes;
		this.formatCnpj;

		this.setRequiredData();
	},
};