import axios from "axios";
import paymentFooter from "../../../components/payment-journey/payment-footer/payment-footer.vue";
import paymentHeader from "../../../components/payment-journey/payment-header/payment-header.vue";

export default {
	components: { paymentFooter, paymentHeader },
	data() {
		return {
			loading: true,
			disabled: false,
			cpf: "",
			cnpj: "",
			amount: 0,
			cpfDisabled: false,
			simpleBrands: [],
			brands: [],
			participants: [],
			search: '',
			dialog: false,
			participantsDetails: [],
			selected: null,
			redirectClient: null,
			primaryColor: null,
			headers: [
				{
					text: 'Nome da instituição',
					align: 'start',
					sortable: false,
					value: 'CustomerFriendlyName',
				},
				{ text: 'Actions', value: 'actions', sortable: false, align: 'end' },
			]
		}
	},
	watch: {
		search: {
			deep: true,
			handler: _.debounce(function() {
				if(this.search.length != 0){
					let search = this.search;
					this.simpleBrands = this.brands.filter(function(item){
						if (item.CustomerFriendlyName.toLowerCase().includes(search.toLowerCase()) && this.count < 6){
							this.count ++
							return true;
						}
						return false;
					}, {count: 0, search})
				}else{
					this.listSimpleBrands();
				}
			}, 500)
		},
	},
	methods: {
		maskCpfCnpj(item) {
			if (item.length <= 11) {
				return item.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
			} else {
				return item.replace(
					/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
					"**.$2.$3/$4-**"
				);
			}
		},

		async listSimpleBrands() {
			if(this.$store.state.preferredbanks){
				const params = new URLSearchParams();
				this.$store.state.preferredbanks.forEach((value, index) => { 
				params.append(`preferredbanks`, value);
				});
				const response = await axios.get("/api/smart-keys/payment-initiation/consents/brands", {params});
				for(const responsedata of response.data){
					this.simpleBrands.push({ AuthorisationServerId: responsedata.brandId, CustomerFriendlyName: responsedata.organizationName, CustomerFriendlyLogoUri: responsedata.organizationLogo });
				}
			}else{
				const response = await axios.get("/open-keys-itp/api/brands/v1/brands?type=PAYMENT&isFavorite=true&limit=8");
				this.simpleBrands = response.data
			}
		},

		async listBrands() {
			const response = await axios.get(`/open-keys-itp/api/brands/v1/brands/?type=PAYMENT`);
			this.brands = response.data
		},

		selectInstitution(brand) {
			this.$store.commit("setBrand", brand.AuthorisationServerId);
			this.$store.commit("setCpf", this.cpf);
			this.$store.commit("setCnpj", this.cnpj);
			this.$store.commit("setCustomerFriendlyName", brand.CustomerFriendlyName);
			this.$store.commit("setCustomerFriendlyLogoUri", brand.CustomerFriendlyLogoUri);
			this.$router.push({ name: 'DepositConfirmSimple' })
		},

		async cancel(){
			await axios.put(`/open-keys-itp/api/journey/${this.$store.state.paymentId}`, { status: "CANCEL" });
			let tags = undefined;
			if(this.$store.state?.metadata?.tags){
				tags = '&' + new URLSearchParams(this.$store.state?.metadata?.tags).toString()
			}
			window.location.href = this.$store.state.callbackClientPage +
								   `?error=journey_cancel&error_description=Jornada%20rejeitado%20pelo%20usu%C3%A1rio&journey-id=${this.$store.state.paymentId}` +
			 					   (this.$store.state?.metadata?.externalId ? `&externalId=${this.$store.state.metadata.externalId}` : "") +
								   (tags ? `${tags}` : "");
		},

		lastPage() {
			this.$router.go(-1)
		},
	},

	async beforeMount() {
		if(this.$store.state.paymentview !== 'deposit-confirmation-simple'){
			this.disabled = true
		}
		
		this.amount = this.$store.state.amount;
		this.cpf = this.maskCpfCnpj(this.$store.state.cpf);
		this.primaryColor = this.$store.state.primaryColor;
		if(this.$store.state.cnpj){
			this.cnpj = this.$store.state.cnpj;
		}
		this.cpfDisabled =  true;

		await this.listSimpleBrands();
		await this.listBrands();
		this.loading = false;
	}

}