import moment from "moment";
import paymentFooter from "../../components/payment-journey/payment-footer/payment-footer.vue";
import paymentHeader from "../../components/payment-journey/payment-header/payment-header.vue";

export default {
	components: { paymentFooter, paymentHeader },
	data: () => ({
		primaryColor: null
	}),
	beforeMount() {
		this.primaryColor = this.$store.state.primaryColor;
		if(this.$route.query.error_description){
			this.message = this.$route.query.error_description;
		}
		if(this.$route.query.type == 'journeyid_cancel'){
			this.message = 'A jornada foi cancelada. Por isso, o pagamento não foi realizado.';
		}
		if(this.$route.query.type == 'journeyid_not_found'){
			this.message = 'A jornada não foi encontrada. Por isso, o fluxo não foi iniciado.';
		}
		if(this.$route.query.type == 'journeyid_consumed'){
			this.message = 'A jornada consumida. Por isso, o fluxo não foi iniciado.';
		}
		if(this.$route.query.type == 'journeyid_expired'){
			this.message = 'A jornada expirada. Por isso, o fluxo não foi iniciado.';
		}
		if(this.$route.query.type == 'access_denied'){
			this.message = 'O consentimento foi cancelado na detentora.';
		}
		// else{
			// this.message = 'A jornada expirou. Por isso, o pagamento não foi realizado.';
		// }
		if(this.$route.query.createdAt){
			this.date = moment(this.$route.query.createdAt).format('DD/MM/YYYY HH:mm:ss')
		}
	},
	data: () => ({
		message: null,
		date: moment().format('DD/MM/YYYY HH:mm:ss'),
	}),
	methods: {
		async cancel(){
			window.location.href = this.$store.state.callbackClientPage;
		}
	}
};