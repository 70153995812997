import _ from "lodash";
import moment from "moment";
import Loading from "../../../components/loading/loading.vue"
import ConsentDialog from "../../../components/consent/dialog/consent-dialog.vue";
import axios from "axios";

/** @type {import("vue").Component} @vue/component */
export default ({
	components: { ConsentDialog, Loading },
	async beforeMount() {
		this.loadPages();
	},
	data: () => ({
		headers: [
			{ text: "organization", value: "organizationName", align: "start", kind: "transmitted" },
			{ text: "brand", value: "organizationName", align: "start", kind: "received" },
			{ text: "creationDateTime", value: "creationDateTime" },
			{ text: "expirationDateTime", value: "expirationDateTime" },
			{ text: "status", value: "status" },
			{ text: "actions", value: "actions", sortable: false, width: "150" }
		],
		types: [
			{ id: "transmitted", name: "transmitted" },
			{ id: "received", name: "received" }
		],
		statusList: [
			{ id: "AWAITING_AUTHORISATION", name: "awaiting-authorisation" },
			{ id: "REJECTED", name: "rejected" },
			{ id: "AUTHORISED", name: "authorised" }
		],
		loading: false,
		type: "received",
		page: 1,
		itemPerPage: 10,
		menu1: false,
		dateFromFormatted: undefined,
		menu2: false,
		dateToFormatted: undefined,
		dialog: false,
		history: [],
		consent: {},
		organization: null,
		loadingTable: false,
		data: null,
		received: [],
		transmitted: [],
		amount: null
	}),
	computed: {
		// listTypes() {
		// 	return this.types.map((item) => ({ ...item, name: item.name }));
		// },
		listHeaders() {
			return this.headers.filter(header => !header.kind || header.kind === this.type).map((item) => ({ ...item, text: item.text }));
		},
		listPayments() {
			return this.type === "received" ? this.received : this.transmitted;
		},
		// pagination() {
		// 	if (this.listPayments.headers) {
		// 		const data = this.listPayments.headers;
		// 		data.pages = parseInt(data["x-total-pages"]);
		// 		return data;
		// 	} else {
		// 		return {};
		// 	}
		// },
		// selectStatus() {
		// 	return this.statusList.map((item) => ({ ...item, name: item.name }));
		// },
		locale() {
			return this.replace("-", "_");
		},
	},
	watch: {
		page() {
			this.loadPages();
		},
		type() {
			this.page = 1;
			this.loadPages();
		}
	},
	methods: {
		async loadPages() {
			this.loadingTable = true;

			if (this.type === "received") {
				// this.received = await axios.get("/api/smart-keys/data-reception/v1/consents", { params: { count: 10, page: 1 } });
				this.received = await axios.get("/api/smart-keys/data-reception/v1/consents");
				this.amount = this.received.data.length;
			} else {
				this.transmitted = await this.$axios.get(`/api/v1/consent/transmitions`, { params: this.query });
				this.amount = this.transmitted.data.length;
			}

			console.log(this.amount);
			this.loadingTable = false;
		},
		async loadEntries() {
			this.page = 1;
			await this.loadPages();
		},
		formatDate(date) {
			if (!date) return null;
			const [ year, month, day ] = date.split("-");
			return this.$i18n.locale === "pt_BR" ? `${day}/${month}/${year}` : `${month}/${day}/${year}`;
		},
		closeDialog() {
			this.dialog = false;
		},
		async revoke(item) {
			if (await this.$confirm("confirm-revoke", { name: item.organizationName })) {
				this.loading = true;
				try {
					this.type === "transmitted" ? await this.$axios.$delete(`/api/v1/consent/transmitions/${item.consentId}`) :
						await this.$axios.$delete(`/api/v1/consent/receptions/${item.consentId}`);
					item.status = "REJECTED";
					this.listPayments.data.splice(this.listPayments.data.findIndex(conset => conset.consentId === item.consentId), 1, item);
					this.$toast.success("success-remove");
					this.closeDialog();
				} catch (error) {
					this.$toast.error(error);
				}
				this.loading = false;
			}

		},
		convertDate(date) {
			const data = moment.utc(date);
			const format = data.format("DD/MM/YYYY");
			if (format !== "Invalid date") {
				return format;
			}
			return null;
		},
		sendToHomePage(){
			return this.$router.push({ path: `/consent-home?id=${this.$store.state.journeyid}&return=true`, params: { return: true } });
		}
	}
});