import axios from "axios";
import moment from "moment";

export default {
	data() {
		return {
			update: true,
			step: 0,
			consent: {},
			dialog: false,
			expirationDateTimeSelected: null,
			deadline: [
				{ text: "6 meses", value: 6 },
				{ text: "9 meses", value: 9 },
				{ text: "12 meses", value: 12 }
			],
			opened: 0,
			checkbox: true,
			loading: false,
			authorizationUrl: null,
			permissions: [
				"ACCOUNTS_READ",
				"ACCOUNTS_OVERDRAFT_LIMITS_READ",
				"ACCOUNTS_BALANCES_READ",
				"ACCOUNTS_TRANSACTIONS_READ",
				"CREDIT_CARDS_ACCOUNTS_BILLS_READ",
				"CREDIT_CARDS_ACCOUNTS_BILLS_TRANSACTIONS_READ",
				"CREDIT_CARDS_ACCOUNTS_LIMITS_READ",
				"CREDIT_CARDS_ACCOUNTS_READ",
				"CREDIT_CARDS_ACCOUNTS_TRANSACTIONS_READ",
				"CUSTOMERS_PERSONAL_ADITTIONALINFO_READ",
				"CUSTOMERS_PERSONAL_IDENTIFICATIONS_READ",
				"LOANS_READ",
				"LOANS_WARRANTIES_READ",
				"LOANS_SCHEDULED_INSTALMENTS_READ",
				"LOANS_PAYMENTS_READ",
				"FINANCINGS_READ",
				"FINANCINGS_WARRANTIES_READ",
				"FINANCINGS_SCHEDULED_INSTALMENTS_READ",
				"FINANCINGS_PAYMENTS_READ",
				"UNARRANGED_ACCOUNTS_OVERDRAFT_READ",
				"UNARRANGED_ACCOUNTS_OVERDRAFT_WARRANTIES_READ",
				"UNARRANGED_ACCOUNTS_OVERDRAFT_SCHEDULED_INSTALMENTS_READ",
				"UNARRANGED_ACCOUNTS_OVERDRAFT_PAYMENTS_READ",
				"INVOICE_FINANCINGS_READ",
				"INVOICE_FINANCINGS_WARRANTIES_READ",
				"INVOICE_FINANCINGS_SCHEDULED_INSTALMENTS_READ",
				"INVOICE_FINANCINGS_PAYMENTS_READ",
				"RESOURCES_READ",
			],

			permissionsSend: null,
			dialogBrand: false,
			accounts: true,
			customersPersonal: true,
			creditCards: true,
			creditOperation: true,

		};
	},
	async created() {
		await axios.get(`/api/smart-keys/data-reception/v1/consents/${this.$route.params.id}`)
		.then( (consent) => {
			this.consent = consent.data;
		});

		if(this.$route.name == "consentRenew") {
			this.update = false;
		}

		if (!this.consent.brandId) {
			return this.$router.push({ name: "consentList" });
		}

		this.expirationDateTimeSelected = this.deadline[2];
	},
	computed: {
		expirationDateTime() {
			return moment().add(this.expirationDateTimeSelected.value, "months").format("DD/MM/YYYY");
		},
		organizationName() {
			return this.consent.organizationName;
		}
	},
	methods: {
		async updateConsent() {
			this.loading = true;

			let cpf = "76109277673"
			if (this.$store.state.cpf) {
				cpf = this.$store.state.cpf;
			}

			const body = {
				expirationDateTime: moment().add(this.expirationDateTimeSelected.value, "months").format("YYYY-MM-DDTHH:mm:ss\\Z"),
				permissions: Object.values(this.permissions),
			};

			if (this.$store.state.cnpj) {
				body.data.businessEntity = { "document": { "identification": "45872424000184", "rel": "CNPJ" } };
				body.data.permissions.splice(body.data.permissions.indexOf("CUSTOMERS_PERSONAL_ADITTIONALINFO_READ"), 1);
				body.data.permissions.splice(body.data.permissions.indexOf("CUSTOMERS_PERSONAL_IDENTIFICATIONS_READ"), 1);
				body.data.permissions.push("CUSTOMERS_BUSINESS_ADITTIONALINFO_READ");
				body.data.permissions.push("CUSTOMERS_BUSINESS_IDENTIFICATIONS_READ");
			}

			let response;
			if(this.update == true){
				response = await axios.put(`/api/smart-keys/data-reception/v1/consents/${this.consent.id}`, body);
			}else{
				response = await axios.patch(`/api/smart-keys/data-reception/v1/consents/${this.consent.id}`, body);
			}

			this.step = 1;
			this.redirect(response.data.authorizationUrl);
		},
		approveTermsAndConditions() {
			this.dialog = false;
		},

		permissionsSet(type) {
			if (type == 'accounts') {
				var accountsPermissions = ['ACCOUNTS_READ', 'ACCOUNTS_BALANCES_READ', 'ACCOUNTS_OVERDRAFT_LIMITS_READ', 'ACCOUNTS_TRANSACTIONS_READ'];
				if (this.accounts == true) {
					this.permissions.push(...accountsPermissions);
				} else {
					this.permissions = this.permissions.filter(item => !accountsPermissions.includes(item))
				}
			} else if (type == 'customersPersonal') {
				var customersPersonalPermissions = ['CUSTOMERS_PERSONAL_IDENTIFICATIONS_READ', 'CUSTOMERS_PERSONAL_ADITTIONALINFO_READ'];
				if (this.customersPersonal == true) {
					this.permissions.push(...customersPersonalPermissions);
				} else {
					this.permissions = this.permissions.filter(item => !customersPersonalPermissions.includes(item))
				}
			} else if (type == 'creditCards') {
				var creditCardsPermissions = ['CREDIT_CARDS_ACCOUNTS_READ', 'CREDIT_CARDS_ACCOUNTS_LIMITS_READ', 'CREDIT_CARDS_ACCOUNTS_TRANSACTIONS_READ', 'CREDIT_CARDS_ACCOUNTS_BILLS_READ', 'CREDIT_CARDS_ACCOUNTS_BILLS_TRANSACTIONS_READ'];
				if (this.creditCards == true) {
					this.permissions.push(...creditCardsPermissions);
				} else {
					this.permissions = this.permissions.filter(item => !creditCardsPermissions.includes(item))
				}
			} else {
				var creditOperationPermissions = [
					'LOANS_READ',
					'LOANS_WARRANTIES_READ',
					'LOANS_SCHEDULED_INSTALMENTS_READ',
					'LOANS_PAYMENTS_READ',
					'FINANCINGS_READ',
					'FINANCINGS_WARRANTIES_READ',
					'FINANCINGS_SCHEDULED_INSTALMENTS_READ',
					'FINANCINGS_PAYMENTS_READ',
					'UNARRANGED_ACCOUNTS_OVERDRAFT_READ',
					'UNARRANGED_ACCOUNTS_OVERDRAFT_WARRANTIES_READ',
					'UNARRANGED_ACCOUNTS_OVERDRAFT_SCHEDULED_INSTALMENTS_READ',
					'UNARRANGED_ACCOUNTS_OVERDRAFT_PAYMENTS_READ',
					'INVOICE_FINANCINGS_READ',
					'INVOICE_FINANCINGS_WARRANTIES_READ',
					'INVOICE_FINANCINGS_SCHEDULED_INSTALMENTS_READ',
					'INVOICE_FINANCINGS_PAYMENTS_READ',
					'CREDIT_CARDS_ACCOUNTS_READ',
					'CREDIT_CARDS_ACCOUNTS_READ'
				];

				if (this.creditOperation == true) {
					this.permissions.push(...creditOperationPermissions);
				} else {
					this.permissions = this.permissions.filter(item => !creditOperationPermissions.includes(item))
				}
			}
			console.log(typeof(this.permissions));
		},

		redirect(authorizationUrl) {
			if (!authorizationUrl) {
				return this.$router.push({ name: "consentList" });
			}

			setTimeout(() => {
				window.location.href = authorizationUrl;
			}, 5000);
		}
	},

	beforeMount() {
		this.permissionsSend = this.permissions;
	},
};