export default {
	async data() {
		return {
			approvedConsent: false,
			redirectParams: null,
		};
	},
	created() {
		if (!this.$store.state.brand || !this.$store.state.authorizationUrl) {
			return this.$router.push({ name: "initialMessageView" });
		}

		setTimeout(() => {
			window.location.replace(this.$store.state.authorizationUrl);
		}, 5000);
	},
	computed: {
		primaryColor() {
			return this.$vuetify.theme.themes.light.primary;
		},
		organizationName() {
			return this.$store.state.brand?.CustomerFriendlyName;
		},
		organizationLogo() {
			return this.$store.state.brand?.CustomerFriendlyLogoUri;
		},
		applicationName(){
			return this.$store.state.applicationName;
		},
		applicationLogo(){
			return this.$store.state.applicationLogo;
		}
	}
};