import axios from "axios";
import moment from "moment";
import Randomstring from "randomstring";

export default {
	data: () => ({
		hasError: false,
		hasSuccess: false,
		dialog: false,
		data: null,
		applicationLogo: null,
		state: null,
		ticket: null,
		payment: false,
		statement: {
			statusUpdateDateTime: 0,
			payment: {
				amount: 0,
				pixkey: "N/A"
			},
			creditor: {
				name: "N/A",
				cpfCnpj: "N/A"
			},
			debitor: {
				cpfCnpj: "N/A"
			},
			customerFriendlyLogoUri: null,
			customerFriendlyName: null,
		}
	}),
	computed: {
		primaryColor() {
			return this.$vuetify.theme.themes.light.primary;
		},
	},
	methods: {
		async viewData() {
			this.dialog = true;
			if (!this.data) {
				this.data = await this.loadData();
			}
		},
		async loadData() {
			const result = {};
			// let version = "";
			let version = "v2";
			this.ticket = { access_token: this.ticket };
			const headers = { Authorization: `Bearer ${this.ticket.access_token}` };

			// try{
				// const resourcesv1 = await axios.get("/api/smart-keys/data-consumer/v1/consume?link=/open-banking/resources/v1/resources", { headers });
				// if(resourcesv1.status === 200){
					// version = "v1";
				// }
			// }catch(error){
				// const resourcesv2 = await axios.get("/api/smart-keys/data-consumer/v1/consume?link=/open-banking/resources/v2/resources", { headers });
				// if(resourcesv2.status === 200){
					// version = "v2";
				// }
			// }

			const { data: { data: [personalDocumentData] } } = await axios.get(`/api/smart-keys/data-consumer/v1/consume?link=/open-banking/customers/${version}/personal/identifications`, { headers });

			result.civilName = personalDocumentData.civilName;
			result.birthDate = moment(personalDocumentData.birthDate).format("DD/MM/YYYY");
			result.cpfNumber = personalDocumentData.documents.cpfNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

			if (personalDocumentData.contacts.phones.length) {
				result.phone = `(${personalDocumentData.contacts.phones[0].areaCode}) ${personalDocumentData.contacts.phones[0].number}`;
			}

			if (personalDocumentData.contacts.emails.length) {
				result.email = personalDocumentData.contacts.emails[0].email;
			}

			const { data: { data: accounts } } = await axios.get(`/api/smart-keys/data-consumer/v1/consume?link=/open-banking/accounts/${version}/accounts`, { headers });
			result.accounts = [];
			for (const account of accounts) {
				const { data: { data: balances } } = await axios.get(`/api/smart-keys/data-consumer/v1/consume?link=/open-banking/accounts/${version}/accounts/${account.accountId}/balances`, { headers });
				const accountTypes = {
					CONTA_DEPOSITO_A_VISTA: "Conta Corrente",
					CONTA_POUPANCA: "Conta Poupança",
					CONTA_PAGAMENTO_PRE_PAGA: "Conta de Pagamento Pré-paga"
				};

				result.accounts.push({
					type: accountTypes[account.type],
					branchCode: account.branchCode,
					number: `${account.number}-${account.checkDigit}`,
					amount: `R$ ${balances.availableAmount}`
				});
			}

			const { data: { data: creditCards } } = await axios.get(`/api/smart-keys/data-consumer/v1/consume?link=/open-banking/credit-cards-accounts/${version}/accounts`, { headers });
			result.creditCards = [];
			for (const creditCard of creditCards) {
				const { data: { data: [limits] } } = await axios.get(`/api/smart-keys/data-consumer/v1/consume?link=/open-banking/credit-cards-accounts/${version}/accounts/${creditCard.creditCardAccountId}/limits`, { headers });
				result.creditCards.push({
					name: `${creditCard.name} ${creditCard.productType}`,
					limitTotal: `R$ ${limits.limitAmount}`,
					usedAmount: `R$ ${limits.usedAmount}`
				});
			}
			return result;
		},

		async loadPayment() {
			this.payment = true;

			this.ticket = { access_token: this.ticket };
			const headers = { Authorization: `Bearer ${this.ticket.access_token}` };

			try {
				const response = await axios.get(`/open-keys-itp/api/payment-consumer/v1/pix/consents`, { headers });

				if (response.data.status == "CONSUMED") {

					this.statement.payment.amount = response.data.payment.amount;
					this.statement.payment.pixkey = response.data.payment.details.proxy;

					this.statement.creditor.name = response.data.creditor.name;
					this.statement.creditor.cpfCnpj =  response.data.creditor.cpfCnpj;

					this.statement.debitor.cpfCnpj = response.data.loggedUser.document.identification

					this.statement.customerFriendlyLogoUri = response.data.organizationLogo;
					this.statement.customerFriendlyName = response.data.organizationName;

					this.data = response.data;
					this.getPix(response.data.data.paymentId);

				} else {
					this.data = response.data;
				}
			} catch (error) {
				console.log(error);
			}
		},

		async runJourney() {
			return this.$router.push({ path: `/?id=${this.$store.state.journeyid}` });
		},

		sendToHomePage() {
			return this.$router.push({ path: `/consent-home?id=${this.$store.state.journeyid}` });
		},

		formateDate(date) {
			if (!date) return ""
			const statusDate = new Date(date);

			const day = statusDate.getDate();
			const month = statusDate.getMonth() + 1;
			const year = statusDate.getFullYear();

			return (date = day + "/" + month + "/" + year);
		},

		formateHour(date) {
			if (!date) return ""
			const statusDate = new Date(date);
			const hour = statusDate.getHours();
			const minutes = statusDate.getMinutes();

			return `${hour < 10 ? '0' + hour : hour}:${minutes}`;
		},

		formatCpf(cpf) {
			const badchars = /[^\d]/g
			const mask = /(\d{3})(\d{3})(\d{3})(\d{2})/
			const formatedCpf = new String(cpf).replace(badchars, "");

			return formatedCpf.replace(mask, "$1.$2.$3-$4");
		},

		formatCnpj(cnpj) {
			const badchars = /[^\d]/g;
			const mask = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;
			const formatedCnpj = new String(cnpj).replace(badchars, "");

			return formatedCnpj.replace(mask, "$1.$2.$3/$4-$5");
		},

		async getPix(paymentId) {
			try {
				const headers = { Authorization: `Bearer ${this.ticket}` };
				const response = await axios.get(`/open-keys-itp/api/payment-consumer/v1/pix/payments/${paymentId}`, { headers });
				this.data = response.data.data;
			} catch (error) {
				console.log(error);
			}
		}
	},
	async beforeMount() {
		const params = {};
		const urlParams = new URLSearchParams(window.location.search);
		for (const param of urlParams) {
			params[param[0]] = param[1];
		}

		if (params.error) {
			this.hasError = true;
		} else {
			const resource = await axios.get(`/open-keys-itp/api/onboard/v1/resources/${params.state}/application`);
			this.applicationLogo = resource.data.logo;
			this.ticket = params.ticket;
			this.hasSuccess = true;
			this.state = params.state;


			if (resource.data.kind == "PAYMENT") {
				this.data = await this.loadPayment();
			} else {
				this.data = await this.loadData();
			}
		}
	},
};