import axios from "axios";

export default {
	data() {
		return {
			name: null,
			cpf: null,
			cnpj: null,
			id: null,
			pix: false,
			search: '',
			dialog: false,
			newAccount: false,
			loading: true,
			brand: null,
			selected: null,
			historyParticipants: [],
			participants: [],
			participantsDetails: [],
			preferredbanks: [],
			dialogCancel: false,

			headers: [
				{
					text: 'Nome da instituição',
					align: 'start',
					sortable: false,
					value: 'CustomerFriendlyName',
				},
				{ text: 'Actions', value: 'actions', sortable: false, align: 'end' },
			],
		}
	},
	methods: {
		async listBrands() {
			const response = await axios.get("/api/smart-keys/participants/v1/brands/?type=PAYMENT");
			this.participants = response.data
			this.loading = false
		},
		async listHistoryBrands() {

			let config = {
				params: {
					cpf: this.cpf
				},
			  }
			  
			  if(this.preferredbanks){
				const params = new URLSearchParams();
				this.preferredbanks.forEach((value, index) => { 
				params.append(`preferredbanks`, value);
				});
				const response = await axios.get("/api/smart-keys/payment-initiation/consents/brands", {params});
            	this.historyParticipants = response.data
			}else{
				let config = {
					params: {
						cpf: this.cpf
					},
				  }

				const response = await axios.get("/api/smart-keys/payment-initiation/consents/brands", config);
				this.historyParticipants = response.data
			}

        },

		maskCpfCnpj(item, type) {
			if (!item) return
			if (type === "cpf") {
				return item.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "***.$2.$3-**");
			} else {
				return item.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "**.$2.$3/$4-**");
			}
		},

		createAccount() {
			this.newAccount = !this.newAccount
		},

		selectInstitution(participant) {
			const id = participant.brandId;
			this.$store.commit("setLogoBrand", participant.organizationLogo)
			if (this.paymentview == 'deposit-confirmation') {
				this.$store.commit("setIsCurrent", true);
				this.$router.push({ path: '/deposit-confirmation/' + id })

			} else {
				this.$router.push({ path: `/deposit-scheduling/` + id })
			}
		},
		rowClick: function (item, row) {
			row.select(true);
			this.selected = true;
			this.brand = item
		},

		confirm() {
			if (this.paymentview == 'deposit-confirmation') {
				this.$store.commit("setIsCurrent", true);
				this.$store.commit("setBrand", this.brand);
				this.$router.push({ path: '/deposit-confirmation/' + this.brand.AuthorisationServerId })
			} else {
				this.$router.push({ path: `/deposit-scheduling/` + this.brand.AuthorisationServerId })
			}
		},
		async cancel() {
			await axios.post(`/api/smart-keys/interceptors/webhooks`, {
				type: "PAYMENT",
				event: "journey:cancel",
				data: {
						journeyId: this.$store.state.paymentId
				}
			});
			const callbackPage = this.$store.state.callbackClientPage;
			window.open(callbackPage, '_self');
		},
		async details(id) {
			this.dialog = !this.dialog
			const response = await axios.get("/api/smart-keys/participants/v1/brands/" + id);
            this.participantsDetails = response.data

		},
		showBrandInformation(item){
			this.dialog = true;
			this.participantsDetails = item;
		}
	},

	async beforeMount() {
		const paymentId = this.$route.query.id
		this.$store.commit("setPaymentId", paymentId);

		let verify = {};

		try{
			verify = await axios.get(`/open-keys-itp/api/auth/v1/verify-id?id=${this.$route.query.id}`);
		}catch(error){
			if(error.response.status === 404){
				this.$router.push({ name: "JourneyError", query: { type: "journeyid_not_found", createdAt: new Date()}});
			}
			if(error.response.status === 422){
				this.$router.push({ name: "JourneyError", query: { type: error.response.data.message, createdAt: new Date()}});
			}
		}
		
		const application = await axios.get(`/api/smart-keys/auth/v1/applications/${verify.data.clientId}`);

		this.$store.commit("setApplicationName", application.data.name);
		this.$store.commit("setApplicationLogo", application.data.logo);
		this.$store.commit("setCallbackClientPage", application.data.redirect_client);

		this.id = verify.data.id;
		this.clientId = verify.data.clientId;
		this.cpf = verify.data.cpf;
		this.cnpj = verify.data.cnpj;
		this.name = verify.data.name;
		this.paymentview = verify.data.paymentview;
		this.preferredbanks = verify.data.preferredbanks;
		
		this.listBrands(),
		this.listHistoryBrands()

		this.$store.commit("setCpf", this.cpf);
		this.$store.commit("setCnpj", this.cnpj);
		this.$store.commit("setName", this.name);
		this.$store.commit("setClientId", this.clientId);
		this.$store.commit("setDepositAmount", verify.data.amount);
		this.$store.commit("setCallbackPage", verify.data.callbackpage);
		this.$store.commit("setPaymenttype", verify.data.paymenttype);
		this.$store.commit("setMetadata", verify.data.metadata);
		this.$store.commit("setDescription", verify.data.description);

		if(verify.data.paymenttype === "MANU"){
			this.$store.commit("setCreditor", verify.data.creditor);
			this.$store.commit("setCreditorAccount", verify.data.creditorAccount);
		}
	},
}
