import axios from "axios";

export default {

	data() {
		return {
			terms: false,
			checkbox: false,
			cpf: null,
			cnpj: null,
			name: null,
			id: null,
			applicationName: null,
			applicationLogo: null,
			applicationScopes: null,
			applicationFinalidade: null
		}

	},

	methods: {
		nextView() {
			this.$store.commit("setCpf", this.cpf);
			this.$store.commit("setCnpj", this.cnpj);
			this.$store.commit("setName", this.name);
			this.$store.commit("setClientId", this.id);
			this.$store.commit("setApplicationName", this.applicationName);
			this.$store.commit("setApplicationLogo", this.applicationLogo);
			this.$store.commit("setScopes", this.applicationScopes);
			this.$store.commit("setFinalidade", this.applicationFinalidade);
			this.$router.push({ name: "selectBrandView" });
		},
		async cancel(){
			await axios.post(`/api/smart-keys/interceptors/webhooks`, {
				type: "PAYMENT",
				event: "journey:cancel",
				data: {
						journeyId: this.$store.state.paymentId
				}
			});
			const callbackPage = this.$store.state.callbackClientPage;
			window.open(callbackPage, '_self');
		}
	},

	async beforeMount() {
		try{
			const paymentId = this.$route.query.id
			this.$store.commit("setPaymentId", paymentId);
	
			const verify = await axios.get(`/api/smart-keys/auth/v1/verify-id?id=${this.$route.query.id}`);
			const application = await axios.get(`/api/smart-keys/auth/v1/applications/${verify.data.id}`);

			this.cpf = verify.data.cpf;
			this.cnpj = verify.data.cnpj;
			this.id = verify.data.id;
			this.name = verify.data.name
			this.applicationName = application.data.name;
			this.applicationLogo = application.data.logo;
			this.applicationScopes = application.data.scopes;
			this.$store.commit("setCallbackPage", verify.data.callbackpage);
	
			if( application.data.finalidade == 1){
				this.applicationFinalidade = "Abertura de Conta";
			}else {
				this.applicationFinalidade = "Análise de Perfil" ;
			}
		}catch(error){

		}
	}
};