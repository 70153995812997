import axios from "axios";
import moment from "moment";
import paymentFooter from "../../components/payment-journey/payment-footer/payment-footer.vue";
import paymentHeader from "../../components/payment-journey/payment-header/payment-header.vue";

export default {
	components: { paymentFooter, paymentHeader },
	data: () => ({
		dialog: false,
		data: null,
		applicationLogo: null,
		state: null,
		ticket: null,
		payment: false,
		hasSuccess: false,
		primaryColor: null,
		redirectClient: null,
		journeyId: null,
		metadata: null,
		statement: {
			statusUpdateDateTime: 0,
			payment: {
				amount: 0,
				pixkey: "N/A"
			},
			creditor: {
				name: "N/A",
				cpfCnpj: "N/A"
			},
			debitor: {
				cpfCnpj: "N/A"
			},
			customerFriendlyLogoUri: null,
			customerFriendlyName: null,
		}
	}),
	computed: {
		primaryColor() {
			return this.$vuetify.theme.themes.light.primary;
		},
	},
	methods: {
		async viewData() {
			this.dialog = true;
			if (!this.data) {
				this.data = await this.loadData();
			}
		},
		async loadData() {
			const result = {};
			let version = "v2";
			this.ticket = { access_token: this.ticket };
			const headers = { Authorization: `Bearer ${this.ticket.access_token}` };

			result.civilName = "";
			result.birthDate = "";
			result.cpfNumber = "";

			result.accounts = [];
			// for (const account of accounts) {
			// 	const { data: { data: balances } } = await axios.get(`/api/smart-keys/data-consumer/v1/consume?link=/open-banking/accounts/${version}/accounts/${account.accountId}/balances`, { headers });
			// 	const accountTypes = {
			// 		CONTA_DEPOSITO_A_VISTA: "Conta Corrente",
			// 		CONTA_POUPANCA: "Conta Poupança",
			// 		CONTA_PAGAMENTO_PRE_PAGA: "Conta de Pagamento Pré-paga"
			// 	};

			// 	result.accounts.push({
			// 		type: accountTypes[account.type],
			// 		branchCode: account.branchCode,
			// 		number: `${account.number}-${account.checkDigit}`,
			// 		amount: `R$ ${balances.availableAmount}`
			// 	});
			// }

			// const { data: { data: creditCards } } = await axios.get(`/api/smart-keys/data-consumer/v1/consume?link=/open-banking/credit-cards-accounts/${version}/accounts`, { headers });
			// result.creditCards = [];
			// for (const creditCard of creditCards) {
			// 	const { data: { data: [limits] } } = await axios.get(`/api/smart-keys/data-consumer/v1/consume?link=/open-banking/credit-cards-accounts/${version}/accounts/${creditCard.creditCardAccountId}/limits`, { headers });
			// 	result.creditCards.push({
			// 		name: `${creditCard.name} ${creditCard.productType}`,
			// 		limitTotal: `R$ ${limits.limitAmount}`,
			// 		usedAmount: `R$ ${limits.usedAmount}`
			// 	});
			// }
			this.hasSuccess = true;
			return result;
		},

		async loadPayment() {
			this.payment = true;

			this.ticket = { access_token: this.ticket };
			const headers = { Authorization: `Bearer ${this.ticket.access_token}` };

			try {
				const response = await axios.get(`/open-keys-itp/api/payment-consumer/v1/pix/consents`, { headers });
				if (response.data.status == "CONSUMED") {
					this.statement.payment.amount = response.data.payment.amount;
					this.statement.payment.pixkey = response.data.payment.details.proxy;
					this.statement.creditor.name = response.data.creditor.name;
					this.statement.creditor.cpfCnpj =  response.data.creditor.cpfCnpj;
					this.statement.debitor.cpfCnpj = response.data.loggedUser.document.identification
					this.statement.customerFriendlyLogoUri = response.data.organizationLogo;
					this.statement.customerFriendlyName = response.data.organizationName;
					this.journeyId = response.data.journeyId;
					this.metadata = response.data.metadata;

					const paymentDate = response.data.payment.date ? response.data.payment.date : response.data.payment.schedule.single.date
					this.statement.statusUpdateDateTime = moment(paymentDate).format('DD/MM/YYYY');
					// const headers = { Authorization: `Bearer ${this.ticket}` };
					// const pixData = await axios.get(`/api/smart-keys/payment-consumer/v1/pix`, { headers });
				} else {
					this.data = response.data;
				}
			} catch (error) {
				console.log(error);
			}
		},

		async runJourney() {
			return this.$router.push({ path: `/?id=${this.$store.state.journeyid}` });
		},

		sendToHomePage() {
			return this.$router.push({ path: `/consent-home?id=${this.$store.state.journeyid}` });
		},

		formateDate(date) {
			if (!date) return ""
			const statusDate = new Date(date);

			const day = statusDate.getDate();
			const month = statusDate.getMonth() + 1;
			const year = statusDate.getFullYear();

			return (date = day + "/" + month + "/" + year);
		},

		formateHour(date) {
			if (!date) return ""
			const statusDate = new Date(date);
			const hour = statusDate.getHours();
			const minutes = statusDate.getMinutes();

			return `${hour < 10 ? '0' + hour : hour}:${minutes}`;
		},

		formatCpf(cpf) {
			const badchars = /[^\d]/g
			const mask = /(\d{3})(\d{3})(\d{3})(\d{2})/
			const formatedCpf = new String(cpf).replace(badchars, "");

			return formatedCpf.replace(mask, "$1.$2.$3-$4");
		},

		formatCnpj(cnpj) {
			const badchars = /[^\d]/g;
			const mask = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;
			const formatedCnpj = new String(cnpj).replace(badchars, "");

			return formatedCnpj.replace(mask, "$1.$2.$3/$4-$5");
		},

		printSection() {
			window.print();
		},

		async confirmDeposit(){
			let tags = undefined;
			if(this.metadata?.tags){
				tags = '&' + new URLSearchParams(this.metadata?.tags).toString()
			}
			window.location.href = this.redirectClient + `?id=${this.state}&journey-id=${this.journeyId}` + 
			(this.metadata?.externalId ? `&externalId=${this.metadata.externalId}` : "") +
			(tags ? `${tags}` : "");;
		}
	},
	async beforeMount() {
		const params = {};
		const urlParams = new URLSearchParams(window.location.search);
		for (const param of urlParams) {
			params[param[0]] = param[1];
		}
		if (params.error) {
			let query = params;
			query.type = params.error
			query.createdAt = new Date();
			this.$router.push({ name: "JourneyError", query: query });
		} else {

			const resource = await axios.get(`/api/smart-keys/auth/v1/resources/${params.state}`);
			
			//const resource = await axios.get(`/open-keys-itp/api/onboard/v1/resources/${params.state}/application`);
			
			if(resource.data.primaryColor){
				this.primaryColor = resource.data.primaryColor;
			}else{
				this.primaryColor = "#8E19FF";
			}

			this.applicationLogo = resource.data.logo;
			this.ticket = params.ticket;
			this.state = params.state;
			this.redirectClient = resource.data.redirect_client;

			if (resource.data.kind == "PAYMENT") {
				this.data = await this.loadPayment();
			} else {
				this.data = await this.loadData();
			}
		}
	},
};