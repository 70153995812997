import moment from "moment";
import History from "../../history/history.vue";
/** @type {import("vue").Component} @vue/component */
export default ({
	components: { History },
	props: {
		dialog: {
			type: Boolean,
			default: null
		},
		consent: {
			type: Object,
			default: null
		},
		organization: {
			type: String,
			default: null
		},
		history: {
			type: Array,
			default: null
		}
	},
	computed: {
		locale() {
			return this.$i18n.locale.replace("_", "-");
		}
	},
	methods: {
		maskCpfCnpj(item, type) {
			if (type === "cpf") {
				return item.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "***.$2.$3-**");
			} else {
				return item.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "**.$2.$3/$4-**");
			}
		},
		convertDate(date) {
			const data = moment.utc(date);
			const locale = this.$i18n.locale === "pt_BR" ? "pt" : "en";
			const format = data.local().locale(locale).format("DD/MM/YYYY HH:MM:SS");
			if (format !== "Invalid date") {
				return format;
			}
			return null;
		}
	}
});