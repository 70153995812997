/** @type {import("vue").Component} @vue/component */
export default ({
	data() {
		return {
			primaryColor: null,
		}
	},
	props: {
	},
	computed: {
	},
	methods: {
	},
	async beforeMount() {
		this.primaryColor = this.$store.state.primaryColor;
	}
});