import axios from "axios";

export default {
	data() {
		return {
		  user: null,
		  password: null,
		  loading: false,
		  modal: false,
		  unauthorized: false,
		  opt: "",
		  length: 4,
		  data: null,
		  erroMessage: false,
		  login: false
		};
	  },

	  methods: {
		async nextPage() {
			this.loading = true;

			const body = {
				username: this.user,
				password: this.password,
			  };

			  await axios
        .post("/api/smart-keys/auth/v1/login", body)
        .then((response) => {
          this.loading = false;
          this.modal = true;
		  this.login = true;
          this.data = response.data;

		  this.$store.commit("login", true);

		  this.$router.push({
			name: "initialMessageView",
			params: { data: this.data },
		  });

        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.modal = true;
            this.loading = false;
            this.unauthorized = true;
			this.erroMessage = true;
          } else {
            this.loading = false;
            this.erroMessage = true;
          }
        });
		}
	  },
};