import Vue from "vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./plugins/store";
import VuetifyConfirm from "vuetify-confirm";
import "./plugins/vuetify-money.js";
import { VueMaskDirective } from 'v-mask';

Vue.use(VuetifyConfirm, {
  vuetify
});

Vue.directive('mask', VueMaskDirective);

Vue.config.productionTip = false;
Vue.component("pulse-loader", PulseLoader);
new Vue({
	vuetify,
	router,
	render: h => h(App),
	store
}).$mount("#app");
