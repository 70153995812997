import axios from "axios";

export default {
	data() {
		return {
			cpf: null,
			cnpj: null,
			name: null,
			id: null,
			applicationName: null,
			applicationLogo: null,
			applicationScopes: null,
			applicationFinalidade: null,
			pix: false,
			paymentId: null,
		}
	},
	methods: {
		depositOpenFinance() {
			this.$store.commit("setCpf", this.cpf);
			this.$store.commit("setCnpj", this.cnpj);
			this.$store.commit("setName", this.name);
			this.$store.commit("setClientId", this.id);
			this.$store.commit("setApplicationName", this.applicationName);
			this.$store.commit("setApplicationLogo", this.applicationLogo);
			this.$store.commit("setScopes", this.applicationScopes);
			this.$store.commit("setFinalidade", this.applicationFinalidade);
			this.$router.push({ path: "/select-institution" + `?id=${this.paymentId }`});
		},
	},
	async beforeMount() {
		try{
			this.paymentId = this.$route.query.id
			this.$store.commit("setPaymentId", paymentId);
			
			const verify = await axios.get(`/api/smart-keys/auth/v1/verify-id?id=${this.$route.query.id}`);
			
			this.cpf = verify.data.cpf;
			this.cnpj = verify.data.cnpj;
			this.id = verify.data.id;
			this.name = verify.data.name;
	
			if (this.pix == true) {
				this.$router.push({ name: 'selectInstitution' });
			}
		}catch(error){
			
		}
	},
}