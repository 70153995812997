import Vue from "vue";
import createPersistedState from "vuex-persistedstate";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
	state() {
		return {
			brand: null,
			authorizationUrl: null,
			cpf: null,
			cnpj: null,
			name: null,
			clientId: null,
			applicationName: null,
			applicationLogo: null,
			scopes: null,
			finalidade: null,
			dateSchedule: null,
			depositAmount: null,
			paymentId: null,
			paymentview: null,
			amount: null,
			callbackPage: null,
			callbackClientPage: null,
			isCurrent: true,
			applicationName: null,
			applicationLogo: null,
			customerFriendlyName: null,
			customerFriendlyLogoUri: null,
			paymenttype: null,
			metadata: null,
			redirectUri: null,
			preferredbanks: null,
			preselectedbank: null,
			description: null,
			primaryColor: null,
			debtorAccount: null
		};
	},
	mutations: {
		setBrand(state, brand) {
			state.brand = brand;
		},
		setAuthorizationUrl(state, authorizationUrl) {
			state.authorizationUrl = authorizationUrl;
		},
		setCpf(state, cpf) {
			state.cpf = cpf;
		},
		setClientId(state, clientId) {
			state.clientId = clientId;
		},
		setApplicationName(state, applicationName) {
			state.applicationName = applicationName;
		},
		setApplicationLogo(state, applicationLogo) {
			state.applicationLogo = applicationLogo;
		},
		setCnpj(state, cnpj) {
			state.cnpj = cnpj;
		},
		setJourneyid(state, journeyid) {
			state.journeyid = journeyid;
		},
		setName(state, name) {
			state.name = name;
		},
		setScopes(state, scopes) {
			state.scopes = scopes;
		},
		setFinalidade(state, finalidade) {
			state.finalidade = finalidade;
		},
		setDateSchedule(state, dateSchedule) {
			state.dateSchedule = dateSchedule;
		},
		setDepositAmount(state, depositAmount) {
			state.depositAmount = depositAmount;
		},
		setLogoBrand(state, logoBrand) {
			state.logoBrand = logoBrand;
		},
		setPaymentId(state, paymentId) {
			state.paymentId = paymentId;
		},
		setPaymentview(state, paymentview) {
			state.paymentview = paymentview;
		},
		setAmount(state, amount) {
			state.amount = amount;
		},
		setCallbackPage(state, callbackPage) {
			state.callbackPage = callbackPage;
		},
		setCallbackClientPage(state, callbackClientPage) {
			state.callbackClientPage = callbackClientPage;
		},
		setIsCurrent(state, isCurrent) {
			state.isCurrent = isCurrent;
		},
		setApplicationName(state, applicationName) {
			state.applicationName = applicationName;
		},
		setApplicationLogo(state, applicationLogo) {
			state.applicationLogo = applicationLogo;
		},
		setPaymenttype(state, paymenttype) {
			state.paymenttype = paymenttype;
		},
		setCreditor(state, creditor) {
			state.creditor = creditor;
		},
		setCreditorAccount(state, creditorAccount) {
			state.creditorAccount = creditorAccount;
		},
		setCustomerFriendlyName(state, customerFriendlyName){
			state.customerFriendlyName = customerFriendlyName;
		},
		setCustomerFriendlyLogoUri(state, customerFriendlyLogoUri){
			state.customerFriendlyLogoUri = customerFriendlyLogoUri;
		},
		setMetadata(state, metadata){
			state.metadata = metadata;
		},
		setRedirectUri(state, redirectUri){
			state.redirectUri = redirectUri;
		},
		setPreferredbanks(state, preferredbanks){
			state.preferredbanks = preferredbanks;
		},
		setPreselectedbank(state, preselectedbank){
			state.preselectedbank = preselectedbank;
		},
		setDescription(state, description){
			state.description = description;
		},
		setPrimaryColor(state, primaryColor){
			state.primaryColor = primaryColor;
		},
		setDebtorAccount(state, debtorAccount){
			state.debtorAccount = debtorAccount;
		}
	},
	plugins: [createPersistedState()],
});

export default store;