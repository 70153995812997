import axios from "axios";
import moment from "moment";

export default {
	data() {
		return {
			name: "",
			cpf: "",
			idBrand: "",
			loading: false,
			date_schedule: "",
			amount: null,
			dialog: false,
			terms: false,
			dialog_warning: false,
			date_now: new Date().toISOString().split("T")[0],
			isCurrent: null,
			bodyPayment: {},
			bodySchedule: {}
		};
	},
	methods: {
		async cancel() {
			await axios.put(`/open-keys-itp/api/journey/${this.$store.state.paymentId}`, { status: "CANCEL" });
			let tags = undefined;
			if(this.$store.state?.metadata?.tags){
				tags = '&' + new URLSearchParams(this.$store.state?.metadata?.tags).toString()
			}
			window.location.href = this.$store.state.callbackClientPage + `?error=journey_cancel&error_description=Jornada%20rejeitado%20pelo%20usu%C3%A1rio&journey-id=${this.$store.state.paymentId}` 
			+ (this.$store.state?.metadata?.externalId ? `&externalId=${this.$store.state.metadata.externalId}` : "") +
			(tags ? `${tags}` : "");
		},

		async confirmDeposit() {
			this.loading = true;

			this.dialog_warning = false;

			try {
				
				const response = await axios.post(
					"/open-keys-itp/api/payment-initiation/v1/consents",
					this.isCurrent ? this.bodyPayment : this.bodySchedule, { headers: { "x-journey-id": this.$store.state.paymentId } }
				);
				this.redirect(response.data.authorizationUrl);

			} catch (error) {
				if(error?.response?.data?.code === 422 && error?.response?.data?.message === "JOURNEY_EXISTS"){
					this.$router.push({ name: "JourneyError", query: { type: "journeyid_consumed", createdAt: new Date()}});
				}else{
					this.$router.push({ name: "TransmitterRedirectError" });
				}
			}

		},

		maskCpfCnpj(item, type) {
			if (!item) return;
			if (type === "cpf") {
				return item.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "***.$2.$3-**");
			} else {
				return item.replace(
					/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
					"**.$2.$3/$4-**"
				);
			}
		},

		redirect(authorizationUrl) {
			this.$router.push({ name: "redirection" });
			setTimeout(() => {
				window.location.href = authorizationUrl;
				// window.open(authorizationUrl);
			}, 1000);
		},

		formateDate(date) {
			if (!date) return "";
			date = date.split("-");
			return (date = date[2] + "/" + date[1] + "/" + date[0]);
		},
	},
	async beforeMount() {
		this.cpf = this.$store.state.cpf;
		this.name = this.$store.state.name;
		this.idBrand = this.$route.params.id;
		this.isCurrent = this.$store.state.isCurrent;

		if (this.isCurrent == true)  {
			this.date_schedule = moment(this.date).format('YYYY-MM-DD');
		}else{
			this.date_schedule = this.$store.state.dateSchedule;
		}
		this.amount = parseFloat(this.$store.state.depositAmount).toFixed(2);

		if(this.$store.state.paymenttype === "DICT"){
			const application = await axios.get(`/api/smart-keys/auth/v1/applications/${this.$store.state.clientId}`);

			let personType = application.data.creditorAccount.type;

			if(personType === "LEGAL_PERSON"){
				personType = "PESSOA_JURIDICA";
			}
			if(personType === "NATURAL_PERSON"){
				personType = "PESSOA_NATURAL";
			}

			if(application.data.creditorAccount){
				this.bodyPayment = {
					data: {
						loggedUser: {
							document: {
								identification: this.cpf,
								rel: "CPF",
							},
						},
						creditor: {
							cpfCnpj: application.data.creditorAccount.taxIdNumber,
							name: application.data.creditorAccount.name,
							personType
						},
						payment: {
							amount: this.amount,
							currency: "BRL",
							date: this.date_schedule,
							details: {
								creditorAccount: {
									accountType: application.data.creditorAccount.accountType,
									ispb: application.data.creditorAccount.participant,
									issuer: application.data.creditorAccount.branch.toString(),
									number: application.data.creditorAccount.accountNumber,
								},
								localInstrument: "DICT",
								proxy: application.data.creditorAccount.pixKey,
							},
							type: "PIX",
						},
					},
					brandId: this.idBrand,
				}

				this.bodySchedule = {
					data: {
						loggedUser: {
							document: {
								identification: this.cpf,
								rel: "CPF",
							},
						},
						creditor: {
							cpfCnpj: application.data.creditorAccount.taxIdNumber,
							name: application.data.creditorAccount.name,
							personType
						},
						payment: {
							amount: this.amount,
							currency: "BRL",
							schedule: {
								single: {
									date: this.date_schedule,
								},
							},
							details: {
								creditorAccount: {
									accountType: application.data.creditorAccount.accountType,
									ispb: application.data.creditorAccount.participant,
									issuer: application.data.creditorAccount.branch.toString(),
									number: application.data.creditorAccount.accountNumber,
								},
								localInstrument: "DICT",
								proxy: application.data.creditorAccount.pixKey,
							},
							type: "PIX",
						},
					},
					brandId: this.idBrand,
				}

			}
		}

		if(this.$store.state.paymenttype === "MANU"){
			this.bodyPayment = {
				data: {
					loggedUser: {
						document: {
							identification: this.cpf,
							rel: "CPF",
						},
					},
					creditor: this.$store.state.creditor,
					payment: {
						amount: this.amount,
						currency: "BRL",
						date: this.date_schedule,
						details: {
							creditorAccount: this.$store.state.creditorAccount,
							localInstrument: "MANU",
						},
						type: "PIX",
					},
				},
				brandId: this.idBrand,
			}

			this.bodySchedule = {
				data: {
					loggedUser: {
						document: {
							identification: this.cpf,
							rel: "CPF",
						},
					},
					creditor: this.$store.state.creditor,
					payment: {
						amount: this.amount,
						currency: "BRL",
						schedule: {
							single: {
								date: this.date_schedule,
							},
						},
						details: {
							creditorAccount: this.$store.state.creditorAccount,
							localInstrument: "MANU",
						},
						type: "PIX",
					},
				},
				brandId: this.idBrand,
			}

		}

		if(this.$store.state.cnpj){
			this.bodyPayment.data.businessEntity = {
				document:{
					identification: this.$store.state.cnpj.replace(/\D+/g, ''),
					rel: "CNPJ",
				}
			}
		}

		if(this.$store.state.metadata){
			this.bodyPayment.metadata = this.$store.state.metadata;
		}

		if(this.$store.state.metadata){
			this.bodyPayment.metadata = this.$store.state.metadata;
		}

		if(this.$store.state.description){
			this.bodyPayment.description = this.$store.state.description;
		}
	},
};
