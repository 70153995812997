
export function formattedDate(date) {
	const formatedDate = new Date(date);
	return ("0" + formatedDate.getDate()).slice(-2) + "/" + ("0" + formatedDate.getMonth()).slice(-2) + "/" + formatedDate.getFullYear()
}

export function formattedDateHour(date) {
	const formatedDate = new Date(date);
	return ("0" + formatedDate.getDate()).slice(-2) + "/" + ("0" + formatedDate.getMonth()).slice(-2) + "/" + formatedDate.getFullYear() + " - " + ("0" + formatedDate.getHours()).slice(-2) + ":" + ("0" + formatedDate.getMinutes()).slice(-2);
}

export function formattedStatus(status) {
	if (status == "SCHEDULED") {
		return "Agendado";
	} else if (status == "CONSUMED") {
		return "Recebido";
	} else if (status == "REJECTED SCHEDULING") {
		return "Agendamento Cancelado";
	} else if (status == "REJECTED") {
		return "Cancelado";
	} else if (status == "AUTHORISED") {
		return "Pendente"
	}else if (status == "AWAITING_AUTHORIZATION") {
		return "Aguardando Aprovação"
	}

	else {
		return status;
	}
}

export function formattedCnpj(cnpj) {
	const badchars = /[^\d]/g;
	const mask = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;
	const formatedCnpj = new String(cnpj).replace(badchars, "");

	return formatedCnpj.replace(mask, "$1.$2.$3/$4-$5");
}

export function formattedCpf(cpf) {
	const badchars = /[^\d]/g
	const mask = /(\d{3})(\d{3})(\d{3})(\d{2})/
	const formatedCpf = new String(cpf).replace(badchars, "");

	return formatedCpf.replace(mask, "$1.$2.$3-$4");
}