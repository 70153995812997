import { render, staticRenderFns } from "./history.vue?vue&type=template&id=1c7701a6&"
import script from "./history.js?vue&type=script&lang=js&"
export * from "./history.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fapp%2Fsrc%2Fcomponents%2Fhistory%2Fhistory.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports