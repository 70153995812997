import paymentFooter from "../../../components/payment-journey/payment-footer/payment-footer.vue";
import paymentHeader from "../../../components/payment-journey/payment-header/payment-header.vue";

/** @type {import("vue").Component} @vue/component */
export default ({
	components: { paymentFooter, paymentHeader },
	data: () => ({
		logo_brand: null,
		applicationLogo: null,
		primaryColor: null
	}),
	async beforeMount() {
		if(this.$store.state.brand){
			this.logo_brand = this.$store.state.customerFriendlyLogoUri;
		}else{
			this.logo_brand = this.$store.state.logoBrand;
		}
		
		this.applicationLogo = this.$store.state.applicationLogo;
		this.primaryColor = this.$store.state.primaryColor;
	}
});
