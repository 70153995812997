import _ from "lodash";

/** @type {import("vue").Component} @vue/component */
export default ({
	props: {
		history: {
			type: Array,
			default: null
		}
	},
	computed: {
		historyOrder() {
			return _.orderBy(this.history, (item) => new Date(item.statusUpdateDateTime), [ "desc" ]);
		},
		locale() {
			return this.$i18n.locale.replace("_", "-");
		}
	},
	methods: {
		statusTextColor(status) {
			return status === "REJECTED" ? "red--text" : status === "AWAITING_AUTHORISATION" ? "yellow--text text--darken-4" : "green--text";
		},
		statusColor(status) {
			return status === "REJECTED" ? "red" : status === "AWAITING_AUTHORISATION" ? "yellow darken-4" : "green";
		},
		statusText(status) {
			return status === "REJECTED" ? this.$t("rejected") : status === "AWAITING_AUTHORISATION" ? this.$t("awaiting-authorisation") : this.$t("authorised");
		}
	}
});