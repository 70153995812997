import axios from "axios";
import { formattedDate, formattedStatus, formattedDateHour } from "../../../utils/utils";

export default {
	data() {
		return {
			statuses: [
				{ id: "AWAITING_AUTHORIZATION", name: "Aguardando Aprovação" },
				{ id: "REJECTED", name: "Cancelado" },
				{ id: "CONSUMED", name: "Recebido" }
			],
			menu: null,
			payments: [],
			filters: {
				cpfCnpj: '',
				status: '',
				intervalType: 0,
				initialDate: null,
				finalDate: null,
				intervalDates: [],
				interval: "1h",
			},
			interval: "",
			menuDialog: false,
			config: []
		}
	},


	computed: {
		dateRangeText() {
			if (this.dates[0] && this.dates[1]) {
				return this.formateDate(this.dates[0]) + ' - ' + this.formateDate(this.dates[1]);
			}
		},
		hours() {
			const hours = [];
			for (let hour = 0; hour < 24; hour++) {
				hours.push(`${hour.toString().padStart(2, "0")}:00`);
				hours.push(`${hour.toString().padStart(2, "0")}:30`);
			}

			return hours;
		},
		query() {
			const query = {};
			console.log(this.filters.cpfCnpj?.length > 11)

			if (this.filters.cpfCnpj?.length >= 11) {
				query[this.filters.cpfCnpj?.length > 11 ? "filter.cnpj" : "filter.cpf"] = this.filters.cpfCnpj;
			} 
			query["filter.status"] = this.filters.status;
			query["filter.creationDateTime.$gte"] = this.filters.initialDate;
			query["filter.creationDateTime.$lte"] = this.filters.finalDate;
			return query;
		}
	},

	watch:{
		"filters.cpfCnpj": {
			deep: true,
			handler: _.debounce(function() {
				this.loadEntries();
			}, 500)
		},
		"filters.status": {
			deep: true,
			handler: _.debounce(function() {
				this.loadEntries();
			}, 500)
		},
		"filters.intervalDates": {
			deep: true,
			handler: _.debounce(function() {
				this.calcInterval();
			}, 500)
		}
	},

	methods: {

		onMenuToggle(opened) {
			if (!opened) {
				this.loadEntries(true);
			}
		},

		disableFutureDates(val) {
			return val <= new Date().toISOString().substring(0, 10);
		},

		formatDateHour(date) {
			return formattedDateHour(date);
		},

		formatStatus(status) {
			return formattedStatus(status);
		},

		sendToHomePage() {
			return this.$router.push({ path: `/consent-home?id=${this.$store.state.journeyid}&return=true`, params: { return: true } });
		},

		showDetails(paymentId) {
			this.$store.commit("setPaymentId", paymentId);
			return this.$router.push({ name: 'PaymentDetailView' });
		},

		async loadEntries() {
			this.config.params = this.query;
			try {
				const response = await axios.get('/api/smart-keys/payment-initiation/v1/consents', this.config);
				this.payments = response.data;
			} catch (error) {
				this.payments = []
				console.log(error);
			}
		},

		calcInterval() {
			//sort array to order by date
			const sorted = this.filters.intervalDates.slice().sort((date1, date2) => new Date(date1).setHours(0, 0, 0, 0) - new Date(date2).setHours(0, 0, 0, 0));

			this.filters.initialDate = sorted[0];
			this.filters.finalDate	 = sorted[1];
			this.interval = this.filters.initialDate + ' ~ ' + this.filters.finalDate;

			this.loadEntries();
		},

		clearIntervalDates() {
			this.filters.initialDate = undefined;
			this.filters.finalDate = undefined;
			this.loadEntries();
		}
	},
	beforeMount() {
		this.loadEntries();
	}
}