export default {
    data() {
        return {
            logo_brand: "",
			applicationLogo: null,
        }
    },
    beforeMount() {
        if(this.$store.state.brand){
            this.logo_brand = this.$store.state.brand.CustomerFriendlyLogoUri;
        }else{
            this.logo_brand = this.$store.state.logoBrand;
        }
        
		this.applicationLogo = this.$store.state.applicationLogo;
	},
}