import axios from "axios";

export default {
	data() {
		return {
			name: "",
			cpf: "",
			idBrand: "",
			checked: "",
			dialog_schedule: false,
			dialog_warning: false,
			date_schedule: "",
			date_chosen_modal: "",
			date_now: false,
			date_limit: new Date().toISOString().split("T")[0],
			authorizationUrl: null,
			brands: null,
			dialog: false,
			deposit_amount: "0",
			label: "Digite o valor aqui",
			placeholder: "Digite o valor aqui",
			outlined: true,
			valueWhenIsEmpty: "",
			options: {
				locale: "pt-BR",
				prefix: "R$",
				suffix: "",
				length: 11,
				precision: 2
			}
		};
	},
	methods: {
		async listBrands() {
			this.idBrand = this.$route.params.id;
			const response = await axios.get("/api/smart-keys/participants/v1/brands/" + this.idBrand);
			this.brands = response.data;
		},

		maskCpfCnpj(item, type) {
			if (!item) return;
			if (type === "cpf") {
				return item.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "***.$2.$3-**");
			} else {
				return item.replace(
					/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
					"**.$2.$3/$4-**"
				);
			}
		},
		setDate(schedule) {
			if (schedule == false) {
				this.date_schedule = new Date().toISOString().split("T")[0];
				this.$store.commit("setIsCurrent", true);
				this.date_now = true;
				this.checked = "now"
			} else {
				this.dialog_schedule = false;
				this.date_now = false;
				this.checked = "scheduling"
				this.date_schedule = this.date_chosen_modal
				this.$store.commit("setIsCurrent", false);
				this.dialog_warning = true
			}
		},

		formateDate(date) {
			date = date.split("-");
			return (date = date[2] + "/" + date[1] + "/" + date[0]);
		},

		async cancel() {
			await axios.post(`/api/smart-keys/interceptors/webhooks`, {
				type: "PAYMENT",
				event: "journey:cancel",
				data: {
						journeyId: this.$store.state.paymentId
				}
			});
			const callbackPage = this.$store.state.callbackClientPage;
			window.open(callbackPage, '_self');
		},

		confirmDeposit() {
			this.$store.commit("setDateSchedule", this.date_schedule)
			this.$store.commit("setDepositAmount", this.deposit_amount)
			this.$store.commit("setLogoBrand", this.brands.CustomerFriendlyLogoUri)

			this.$router.push({path: `/deposit-confirmation/` + this.idBrand})
		}
	},
	async beforeMount() {
		this.listBrands();
		this.name = this.$store.state.name;
		this.cpf = this.$store.state.cpf;
	},
};
