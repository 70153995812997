import axios from "axios";
import { formattedDate, formattedStatus, formattedCnpj, formattedCpf, formattedDateHour } from "../../../utils/utils";


export default {
	data() {
		return {
			data: {},
			dialog: false,
			cancel: false,
			newDate: null,
			nowDate: new Date().toISOString().slice(0, 10),
		}
	},

	methods: {
		formatDateHour(date) {
			return formattedDateHour(date);
		},
		formatDate(date) {
			return formattedDate(date);
		},

		formatStatus(status) {
			return formattedStatus(status);
		},

		formatCnpj(cpfCnpj) {
			if (cpfCnpj.length > 11) {
				return formattedCnpj(cpfCnpj);
			} else {
				return formattedCpf(cpfCnpj);
			}
		},

		sendBack() {
			this.dialog = false;
			this.$router.push({ name: "PaymentView" })
		},

		cancelPayment() {
			this.cancel = true;
			this.dialog = true;
		},
		changeDate() {
			this.cancel = false;
			this.dialog = true;
		},
		customFormatHeader(isoDate) {
			const currentDate = new Date(isoDate)
			const month = currentDate.toLocaleString('default', { month: 'long' })
			const year = currentDate.toLocaleString('default', { year: 'numeric' })
			return month + ' ' + year
		},

		async getPayment() {

		}
	},

	async beforeMount() {
		const paymentId = this.$store.state.paymentId;

		try {
			const response = await axios.get(`/api/smart-keys/payment-initiation/v1/consents/${paymentId}`)
			this.data = response.data;
		} catch (error) {
			console.log(error);
		}
	}
}